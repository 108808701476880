import React, { useContext } from 'react';
import { ScrollView, View, Text, ImageBackground, StyleSheet, Dimensions, TouchableOpacity, } from 'react-native';
import AuthContext from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/AuthContext.js';

const { width, height } = Dimensions.get('window');

const StartScreen = ({ navigation }) => {
  const { isAuthenticated } = useContext(AuthContext);

  const handlePress = () => {
    // Wenn der Benutzer authentifiziert ist, führe eine Aktion aus, sonst navigiere zur Login-Seite
    if (isAuthenticated) {
      // Führe hier eine Aktion für authentifizierte Benutzer aus
    } else {
      navigation.navigate('Konto'); // Navigiere zur Login-Seite
    }
  };
  return (
    <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <ImageBackground
        source={{ uri: 'https://cdn.discordapp.com/attachments/1163122066921369780/1173711186659913768/stephan_phohtorealistic_clean_scenery_of_two_people_thinking_ab_a3eadf5a-1e89-43b7-b1db-76ff91423992.png?ex=6564f2b9&is=65527db9&hm=9181f5b7498b2db720b298f13c983167337c2783fe3caff926bb9ae53697274e&' }}
        style={styles.backgroundImage}
      >
        <Text style={styles.headerText}>Geschenkly Plus</Text>
        <View style={styles.featuresOverlay}>
            <Text style={styles.featureText}>• Wunschlisten anlegen und verschicken</Text>
            <Text style={styles.featureText}>• keine Geburtstage mehr vergessen</Text>
            <Text style={styles.featureText}>• automatisierte Geschenkideen zum Wunschtermin</Text>
          </View>
          <TouchableOpacity style={styles.ctaButton} onPress={handlePress}>
            <Text style={styles.ctaText}>kostenlos nutzen</Text>
          </TouchableOpacity>
      </ImageBackground>


      {/* Zweite Sektion
      <View style={styles.section}>
        <Text style={styles.sectionText}>Hier ist etwas Text für Sektion 2.</Text>
      </View>

      {/* Dritte Sektion *
      <View style={styles.section}>
        <Text style={styles.sectionText}>Hier ist etwas Text für Sektion 3.</Text>
      </View>

      {/* Vierte Sektion */}
    
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    width: width, // Volle Breite
    height: height, // Volle Höhe
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    fontSize: 28,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Schwarzer halbtransparenter Hintergrund
    padding: 10, // Etwas Platz um den Text herum
    width: '80%', // Breite des Textcontainers
    borderRadius: 10,
    marginTop: 150, // Abgerundete Ecken
  },
  overlay: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  featuresOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Weißer halbtransparenter Hintergrund
    borderRadius: 10,
    padding: 20,
    marginTop: 10, // Abstand vom Header-Text
  },
  featureText: {
    fontSize: 18,
    color: '#333', // Dunkelgrauer Text auf hellem Hintergrund
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 10, // Abstand zwischen den Feature-Texten
  },
  ctaButton: {
    marginTop: 20,
    backgroundColor: '#ff6f00', // Farbe des Buttons
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
    borderWidth: 2,
    borderColor: 'white', // Weiße Umrandung
  },
  ctaText: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  section: {
    height: 300, // Jede Sektion hat eine Höhe von 300, kann aber angepasst werden
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#E8E8E8', // Trennlinie zwischen den Sektionen
    backgroundColor: 'white', // Hintergrundfarbe der Sektion
  },
  sectionText: {
    fontSize: 22,
    color: 'black',
    padding: 20, // Innenabstand für den Textbereich
    textAlign: 'center',
  },
});

export default StartScreen;
