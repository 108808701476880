import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Input, Button } from 'react-native-elements';

const ResetPassword = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);

  const handlePasswordReset = async () => {
    if (!email) {
      setError('Bitte geben Sie Ihre E-Mail-Adresse ein.');
      return;
    }
    try {
      const response = await fetch('https://www.schindler-ventures.de:3000/requestPasswordReset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error);
      }

      setError('Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail-Adresse gesendet.');
      setResetSuccess(true);
    } catch (error) {
      console.error('Error requesting password reset:', error);
      setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        Bitte geben Sie Ihre E-Mail-Adresse ein, um das Passwort zurückzusetzen
      </Text>

      {error ? <Text style={styles.error}>{error}</Text> : null}

      <Input
        label="E-Mail"
        value={email}
        onChangeText={setEmail}
        autoCapitalize="none"
        keyboardType="email-address"
        inputStyle={styles.input}
        labelStyle={styles.label}
      />

      {!resetSuccess && (
        <Button
          title="Passwort zurücksetzen"
          onPress={handlePasswordReset}
          buttonStyle={styles.button}
        />
      )}

      {resetSuccess && (
        <Button
          title="Zurück zum Login"
          onPress={() => navigation.navigate('Friends')}
          buttonStyle={styles.button}
        />
      )}
    </View>
  );
};



const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
    backgroundColor: '#3C3F41',
  },
  text: {
    fontSize: 18,
    color: '#fff',
    marginBottom: 30,
    textAlign: 'center',
  },
  error: {
    fontSize: 16,
    color: '#ff0000',
    textAlign: 'center',
    marginBottom: 10,
  },
  input: {
    color: '#fff',
  },
  label: {
    color: '#fff',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#EC823A',
    marginBottom: 10,
  },
});

export default ResetPassword;
