import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  Alert,
} from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { RadioButton } from 'react-native-paper';
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';

const ErinnerungAnlegen = () => {
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [occasion, setOccasion] = useState('');
  const [relationship, setRelationship] = useState('');
  const [age, setAge] = useState('');
  const [ageError, setAgeError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [gender, setGender] = useState('');
  const [reminder, setReminder] = useState('');
  const [notification, setNotification] = useState('');

  const [message, setMessage] = useState(null);

  const saveMessage = (msg, type) => {
  setMessage(null); // Reset the state
  setMessage({ content: msg, type: type });
  setTimeout(() => setMessage(null), 5000); // remove message after 5 sec
};


  useEffect(() => {
    validateDate(date);
    validateAge(age);
  }, [date, age]);

  const validateAge = (age) => {
    const isValid = /^\d+$/.test(age);
    setAgeError(!isValid);
  };
  const validateDate = (date) => {
    const isValid = /^\d{2}\.\d{2}\.\d{4}$/.test(date);
    setDateError(!isValid);
  };

  const saveReminder = async () => {
    if (!age) {
      setAgeError(true);
      return;
    }
    if (dateError) {
      return;
    }
    try {
      const token = await AsyncStorage.getItem('token');
      let userId = null;
      if (token) {
        const decoded = jwtDecode(token);
        userId = decoded.userId;
      }
      const response = await fetch(
        'https://www.schindler-ventures.de:3000/reminders',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            date,
            occasion,
            relationship,
            age,
            gender,
            reminder,
            notification,
            userId,
          }),
        }
      );
      if (response.ok) {
  Alert.alert("Erinnerung erfolgreich angelegt");
  setName('');
  setDate('');
  setOccasion('');
  setRelationship('');
  setAge('');
  setGender('');
  setReminder('');
  setNotification('');
  saveMessage('Erinnerung erfolgreich angelegt!', 'success'); // Add this line
} else {
  Alert.alert("Fehler beim Speichern der Erinnerung");
  saveMessage('Fehler beim Speichern der Erinnerung.', 'error'); // Add this line
}
    } catch (error) {
      Alert.alert("Fehler beim Speichern der Erinnerung");
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>Erinnerung anlegen</Text>
      {message && (
        <Text style={message.type === 'success' ? styles.successMessage : styles.errorMessage}>
          {message.content}
        </Text>
      )}
      <TextInput
        style={styles.input}
        onChangeText={setName}
        value={name}
        placeholder="Name / Spitzname"
      />
      <TextInput
  style={styles.input}
  onChangeText={setDate}
  value={date}
  placeholder="Datum (TT.MM.JJJJ)"
/>
{dateError && (
  <Text style={styles.errorText}>Das Datum muss im Format TT.MM.JJJJ sein.</Text>
)}

      <Picker
        style={styles.picker}
        selectedValue={occasion}
        onValueChange={(itemValue, itemIndex) => setOccasion(itemValue)}
      >
        <Picker.Item label="Anlass" value="" />
        <Picker.Item label="Geburtstag" value="Geburtstag" />
        <Picker.Item label="Weihnachten" value="Weihnachten" />
        <Picker.Item label="Jahrestag" value="Jahrestag" />
      </Picker>
      <Picker
        style={styles.picker}
        selectedValue={relationship}
        onValueChange={(itemValue, itemIndex) => setRelationship(itemValue)}
      >
        <Picker.Item label="Beziehung" value="" />
        <Picker.Item label="Familie" value="Familie" />
        <Picker.Item label="Partner" value="Partner" />
        <Picker.Item label="guter FreundIn" value="guter FreundIn" />
        <Picker.Item label="BekannterIn" value="BekannterIn" />
      </Picker>
      <TextInput
        style={styles.input}
        onChangeText={setAge}
        value={age}
        placeholder="Alter"
        keyboardType="numeric"
        onBlur={() => validateAge(age)}
      />
      {ageError && (
        <Text style={styles.errorText}>Nur Zahlen bitte.</Text>
      )}
      {ageError && (
        <Text style={styles.errorText}>Bitte Alter eingeben.</Text>
      )}
      <RadioButton.Group
        onValueChange={(newValue) => setGender(newValue)}
        value={gender}
      >
        <View style={styles.radioRow}>
          <RadioButton value="männlich" />
          <Text style={styles.radioLabel}>Männlich</Text>
          <RadioButton value="weiblich" />
          <Text style={styles.radioLabel}>Weiblich</Text>
        </View>
      </RadioButton.Group>
      <Picker
        style={styles.picker}
        selectedValue={reminder}
        onValueChange={(itemValue, itemIndex) => setReminder(itemValue)}
      >
        <Picker.Item label="Gewünschte Erinnerung" value="" />
        <Picker.Item label="1 Woche vorher" value="1 Woche vorher" />
        <Picker.Item label="2 Wochen vorher" value="2 Wochen vorher" />
        <Picker.Item label="1 Monat vorher" value="1 Monat vorher" />
      </Picker>
      <Picker
        style={styles.picker}
        selectedValue={notification}
        onValueChange={(itemValue, itemIndex) => setNotification(itemValue)}
     >
       <Picker.Item label="Geschenkvorschläge per" value="" />
       <Picker.Item label="E-Mail" value="E-Mail" />
     </Picker>
     <TouchableOpacity style={styles.button} onPress={saveReminder}>
        <Text style={styles.buttonText}>Erinnerung hinzufügen</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
 container: {
   flex: 1,
   padding: 20,
 },
 text: {
   fontSize: 28,
   fontWeight: 'bold',
   marginBottom: 20,
 },
 input: {
   height: 40,
   width: '100%',
   borderColor: 'gray',
   borderWidth: 1,
   marginTop: 10,
   paddingLeft: 10,
   borderRadius: 5,
 },
 picker: {
   height: 50,
   width: '100%',
   marginTop: 10,
 },
 radioRow: {
   flexDirection: 'row',
   alignItems: 'center',
   marginTop: 10,
 },
 radioLabel: {
   marginRight: 10,
 },
 errorText: {
   color: 'red',
   marginTop: 5,
 },
 successMessage: {
  color: 'green',
  marginTop: 5,
  fontSize: 18,
  textAlign: 'center',
},
errorMessage: {
  color: 'red',
  marginTop: 5,
  fontSize: 18,
  textAlign: 'center',
},

 button: {
   marginTop: 20,
   backgroundColor: 'tomato',
   paddingHorizontal: 20,
   paddingVertical: 10,
   borderRadius: 5,
 },
 buttonText: {
   color: 'white',
   fontSize: 16,
 },
});

export default ErinnerungAnlegen;
