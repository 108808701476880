import React, { useState } from 'react';
import { View, Text, TextInput, Button, StyleSheet, ActivityIndicator } from 'react-native';
import { Configuration, OpenAIApi } from 'openai';
import { TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import { useNavigation } from '@react-navigation/native';

const GeschenkeAssistent = () => {
  const navigation = useNavigation();
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);

  const configuration = new Configuration({
    apiKey: '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',
  });
  const openai = new OpenAIApi(configuration);

  const fetchAnswer = async () => {
    setLoading(true);
    try {
      // Save the question
      await fetch('https://www.schindler-ventures.de:3000/api/save-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: input }),
      });

      // Fetch the answer
      const result = await fetch('https://www.schindler-ventures.de:3000/api/ask-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: input }),
      });

      const data = await result.json();
      setResponse(data.answer);
    } catch (err) {
      console.log('Error: ' + err);
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.text}>Lass Dich inspirieren...</Text>
      <Text style={styles.smallText}>
        Beispielfrage: Was kann ich meiner Freundin zum ersten Jahrestag schenken? Es soll etwas Besonderes und Persönliches sein.
      </Text>

      <TextInput
        style={styles.input}
        onChangeText={setInput}
        value={input}
        placeholder="Gebe hier Deine Frage ein"
      />
      <Button title="los gehts" onPress={fetchAnswer} />
      {loading ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#EC823A" />
          <Text style={styles.loadingText}>Ich denke nach und habe die Antwort gleich für Dich parat...</Text>
        </View>
      ) : response ? (
        <View style={styles.responseContainer}>
          <Text style={styles.response}>{response}</Text>
        </View>
      ) : null}
    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3C3F41',
  },
  text: {
    fontSize: 24,
    color: '#fff',
  },
  smallText: {
    fontSize: 14,
    color: 'gray',
    fontStyle: 'italic',
    textAlign: 'center',
    paddingHorizontal: 20,
    color: '#fff',
  },
  input: {
    height: 100,
    width: '80%',
    borderColor: 'gray',
    borderWidth: 1,
    marginTop: 20,
    paddingLeft: 10,
    paddingTop: 10,
    fontSize: 16,
    borderRadius: 10,
    backgroundColor: '#f1f1f1',
  },
  responseContainer: {
    marginTop: 20,
    width: '80%',
    padding: 15,
    backgroundColor: '#f1f1f1',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ddd',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  response: {
    fontSize: 18,
    lineHeight: 24,
    textAlign: 'justify',
    paddingHorizontal: 5,
  },
  loadingContainer: {
    marginTop: 20,
    alignItems: 'center',
  },
  loadingText: {
    fontSize: 16,
    color: 'gray',
    fontStyle: 'italic',
    textAlign: 'center',
    paddingHorizontal: 20,
    color: '#fff',
  },
});

export default GeschenkeAssistent;
