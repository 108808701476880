import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/FontAwesome';

const BirthdayReminder = () => {
  const navigation = useNavigation();

  React.useLayoutEffect(() => {
    navigation.setOptions({
    });
  }, [navigation]);

  const renderButton = (iconName, buttonText, navigationTarget) => (
    <TouchableOpacity
      style={styles.button}
      onPress={() => navigation.navigate(navigationTarget)}
    >
      <Icon name={iconName} size={50} color="#fff" />
      <Text style={styles.iconText}>{buttonText}</Text>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      <Text style={styles.text}>Hier kannst du Erinnerungen anlegen und automatische Geschenkvorschläge bestellen </Text>
      <Text style={styles.text}>Verpasse keine Geburtstage oder Jahrestage mehr</Text>
      <View style={styles.iconContainer}>
        {renderButton('list', 'Alle Erinnerungen', 'AlleErinnerungen')}
        {renderButton('plus', 'Erinnerung anlegen', 'ErinnerungAnlegen')}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3C3F41',
  },
  text: {
    fontSize: 36,
    color: '#fff',
    marginBottom: 30,
  },
  iconContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
  },
  button: {
    backgroundColor: '#EC823A',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 10,
  },
  iconText: {
    fontSize: 18,
    textAlign: 'center',
    marginTop: 10,
    color: '#fff',
  },
});

export default BirthdayReminder;
