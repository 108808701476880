import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Anlass from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/WishList/Anlass.js';
import Box1 from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/WishList/Box1.js';
import Box2 from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/WishList/Box2.js';
import Box3 from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/WishList/Box3.js';
import Box5 from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/WishList/Box5.js';

const Stack = createStackNavigator();

const AppNavigator = () => {
  return (
    <Stack.Navigator initialRouteName="Anlass">
      <Stack.Screen
        name="Anlass"
        component={Anlass}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Box1"
        component={Box1}
        options={{ headerShown: false, title: 'Anlass' }}
      />
      <Stack.Screen
        name="Box2"
        component={Box2}
        options={{ headerShown: true, title: 'Wünsche zum Geburtstag' }}
      />
      <Stack.Screen
        name="Box3"
        component={Box3}
        options={{ headerShown: false, title: 'Wünsche auf Amazon finden' }}
      />
      <Stack.Screen
        name="Box5"
        component={Box5}
        options={{ headerShown: true, title: 'Baby Geschenke' }}
      />
    </Stack.Navigator>
  );
};

export default AppNavigator;
