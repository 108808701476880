import React, { useContext } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import WishlistCountContext from '../screens/WishlistCountContext';
import AuthContext from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/AuthContext.js';

const HeaderOccasion = ({ navigation, onLoginPress, onWishlistPress }) => {
  const { wishlistCount } = useContext(WishlistCountContext);
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <View style={styles.header}>
      <Text style={styles.headerTitle}>Geschenkly Wähle einen Anlass </Text>
      <TouchableOpacity onPress={onWishlistPress}>
        <View style={styles.wishlistCountContainer}>
          <FontAwesome name="gift" size={24} color="#3f7ea3" />
          <Text style={styles.wishlistCountText}>zurück zur Wunschliste</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#3f7ea3',
  },
  headerTitle: {
    fontSize: 24,
    color: '#fff',
  },
  wishlistCountContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  wishlistCountText: {
    color: '#3f7ea3',
    fontSize: 16,
    marginLeft: 5,
  },
});

export default HeaderOccasion;
