import { initializeApp, getApps } from 'firebase/app';
import { getAnalytics, logEvent as logEventFirebase } from "firebase/analytics";

// Use your config values here
const firebaseConfig = {
  apiKey: "AIzaSyCfVn3K9HCFriXPALOAbg8lRhWQUt8kdSY",
  authDomain: "geschenkly-app.firebaseapp.com",
  projectId: "geschenkly-app",
  storageBucket: "geschenkly-app.appspot.com",
  messagingSenderId: "748756944843",
  appId: "1:748756944843:web:870907d97e121de900584e",
  measurementId: "G-J2SJ3ZFBEK"
};

let app;

if (getApps().length === 0) {
    app = initializeApp(firebaseConfig);
} else {
    app = getApps()[0]; // if already initialized, use that one
}

export const analytics = getAnalytics(app);

export const logEvent = (eventName, eventParams, options) => {
  return logEventFirebase(analytics, eventName, eventParams, options);
}
