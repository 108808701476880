import React, { useState } from 'react';
import { View, Text, TouchableOpacity, TextInput, Button, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { useNavigation } from '@react-navigation/native';
import HeaderOccasion from '../HeaderOccasion';
import { NavigationContainer, useFocusEffect } from '@react-navigation/native';

const Box1 = () => {
  const [occasion, setOccasion] = useState('');
  const [occasionDate, setOccasionDate] = useState('');
  const [occasionTime, setOccasionTime] = useState('');
  const [occasionMessage, setOccasionMessage] = useState('');
  const [selectedIcon, setSelectedIcon] = useState(null);
  const navigation = useNavigation();

  const navigateToWishlist = () => {
    navigation.navigate('Deine Wunschliste');
  };

  const saveOccasion = async () => {
    const newOccasion = {
      occasion: occasion,
      date: occasionDate,
      time: occasionTime,
      message: occasionMessage,
    };
    await AsyncStorage.setItem('occasion', JSON.stringify(newOccasion));
    navigateToWishlist();
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <HeaderOccasion
          navigation={navigation}
          onLoginPress={() => navigation.navigate('Login')}
          onWishlistPress={navigateToWishlist}
        />
      </View>
      <View style={styles.content}>
        <View style={styles.iconGrid}>
          <TouchableOpacity style={styles.iconBox} onPress={() => { setSelectedIcon(1); setOccasion('Hochzeit') }}>
            <FontAwesome name="heart" size={100} color={selectedIcon === 1 ? "#e91e63" : "#000"} />
            <Text style={styles.iconText}>Hochzeit</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.iconBox} onPress={() => { setSelectedIcon(2); setOccasion('Geburtstag') }}>
            <FontAwesome name="birthday-cake" size={100} color={selectedIcon === 2 ? "#e91e63" : "#000"} />
            <Text style={styles.iconText}>Geburtstag</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.iconBox} onPress={() => { setSelectedIcon(3); setOccasion('Jahrestag') }}>
            <FontAwesome name="calendar" size={100} color={selectedIcon === 3 ? "#e91e63" : "#000"} />
            <Text style={styles.iconText}>Jahrestag</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.iconBox} onPress={() => { setSelectedIcon(4); setOccasion('Weihnachten') }}>
            <FontAwesome name="tree" size={100} color={selectedIcon === 4 ? "#e91e63" : "#000"} />
            <Text style={styles.iconText}>Weihnachten</Text>
          </TouchableOpacity>
        </View>

        <TextInput
          style={styles.messageInputStyle}
          placeholder="Gib hier deine Botschaft ein..."
          placeholderTextColor="#aaa"
          value={occasionMessage}
          onChangeText={setOccasionMessage}
          multiline
          textAlignVertical='top'
        />

        <TextInput
  style={styles.dateInputStyle}
  placeholder="TT.MM.JJJJ (Datum des Anlasses)"
  placeholderTextColor="#aaa"
  value={occasionDate}
  onChangeText={setOccasionDate}
/>
<TextInput
  style={styles.timeInputStyle}
  placeholder="HH:MM (Uhrzeit des Anlasses)"
  placeholderTextColor="#aaa"
  value={occasionTime}
  onChangeText={setOccasionTime}
/>

        <Button title="Anlass speichern und schließen" onPress={saveOccasion} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
  header: {
    width: '100%',
  },
  content: {
    flex: 1,
    flexDirection: 'column',
  },
  iconGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginBottom: 20,
  },
  iconBox: {
    width: '45%',
    height: 120,
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2.5%',
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 10,
    backgroundColor: '#f9f9f9',
  },
  iconText: {
    marginTop: 10,
    fontSize: 18,
    fontFamily: 'Arial', // Wählen Sie eine passende Schriftart
    color: '#333',
  },
  messageInputStyle: {
    height: 100,
    borderColor: '#ddd',
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: '#f9f9f9',
    paddingHorizontal: 10,
    marginTop: 20,
    textAlign: 'center',
    textAlignVertical: 'top',
  },
  dateInputStyle: {
    height: 50,
    borderColor: '#ddd',
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: '#e9f1f7',
    paddingHorizontal: 10,
    marginTop: 20,
    textAlign: 'center',
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  timeInputStyle: {
    height: 50,
    borderColor: '#ddd',
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: '#e9f1f7',
    paddingHorizontal: 10,
    marginTop: 20,
    textAlign: 'center',
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
});

export default Box1;
