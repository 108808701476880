import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import jwtDecode from 'jwt-decode';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FontAwesome from 'react-native-vector-icons/FontAwesome';

const fetchUserWishlists = async (userId) => {
  try {
    const response = await fetch(
      `https://www.schindler-ventures.de:3000/saved-items?userId=${userId}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    const json = await response.json();
    console.log('Wunschlisten',json)
    return json;
  } catch (error) {
    console.error('Error fetching user wishlists:', error);
  }
};




const DeineWunschlisten = () => {
  const [userWishlists, setUserWishlists] = useState([]);
  const [selectedWishlist, setSelectedWishlist] = useState(null);
  const removeItemFromWishlist = async (wishlist, itemToRemove) => {
    console.log('Item to remove:', itemToRemove);
    try {
      const response = await fetch(
        `https://www.schindler-ventures.de:3000/saved-items/${wishlist._id}`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ itemToRemove }),
        }
      );

      if (response.ok) {
        console.log('Item entfernt:', itemToRemove.name);
        // Aktualisiere die Benutzeroberfläche, um das entfernte Item zu aktualisieren
        const updatedWishlists = userWishlists.map((wl) => {
          if (wl._id === wishlist._id) {
            return { ...wl, items: wl.items.filter((item) => item._id !== itemToRemove._id) };
          }
          return wl;
        });
        setUserWishlists(updatedWishlists);
      } else {
        console.error('Fehler beim Entfernen des Items:', response.status);
      }
    } catch (error) {
      console.error('Fehler beim Entfernen des Items:', error);
    }
  };


  const deleteWishlist = async (wishlistToDelete) => {
  try {
    const response = await fetch(
      `https://www.schindler-ventures.de:3000/saved-items/${wishlistToDelete._id}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.ok) {
      console.log('Wunschliste gelöscht:', wishlistToDelete.title);
      // Aktualisiere die Benutzeroberfläche, um die gelöschte Wunschliste zu entfernen
      const updatedWishlists = userWishlists.filter(
        (wishlist) => wishlist._id !== wishlistToDelete._id
      );
      setUserWishlists(updatedWishlists);
    } else {
      console.error('Fehler beim Löschen der Wunschliste:', response.status);
    }
  } catch (error) {
    console.error('Fehler beim Löschen der Wunschliste:', error);
  }
};



  useEffect(() => {
    (async () => {
      const token = await AsyncStorage.getItem('token');
      let userId = null;
      if (token) {
        const decoded = jwtDecode(token);
        userId = decoded.userId;
      }

      if (userId) {
        fetchUserWishlists(userId).then((data) => {
          setUserWishlists(data.items);
        });
      }
    })();
  }, []);

  const onWishlistTitlePress = (wishlist) => {
    setSelectedWishlist(wishlist);
  };

  return (
   <ScrollView style={styles.container}>
     <Text style={styles.text}>Deine Wunschlisten</Text>
     {userWishlists.map((wishlist, index) => (
       <View style={styles.wishlistCard} key={index}>
         <View style={styles.wishlistHeader}>
           <TouchableOpacity
             style={styles.wishlistTitleButton}
             onPress={() => onWishlistTitlePress(wishlist)}
           >
             <FontAwesome name="eye" size={24} color="white" style={styles.icon} />
             <Text style={styles.wishlistTitle}>{wishlist.title}</Text>
           </TouchableOpacity>
           <TouchableOpacity
             style={styles.deleteButton}
             onPress={() => deleteWishlist(wishlist)}
           >
             <FontAwesome name="trash" size={24} color="white" />
           </TouchableOpacity>
         </View>
         {selectedWishlist === wishlist && (
           <View style={styles.itemList}>
             {wishlist.items.map((item, i) => (
               <View key={i} style={styles.itemRow}>
                 <Text style={styles.itemText}>{item.name}</Text>
                 <TouchableOpacity
                   style={styles.removeItemButton}
                   onPress={() => removeItemFromWishlist(wishlist, item)}
                 >
                   <FontAwesome name="times" size={24} color="white" />
                 </TouchableOpacity>
               </View>
             ))}
           </View>
         )}
       </View>
     ))}
   </ScrollView>
 );
};



const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 20,
    backgroundColor: '#F5F5F5',
  },
  text: {
    fontSize: 28,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#3C3F41',
  },
  wishlistCard: {
    marginBottom: 20,
    backgroundColor: '#fff',
    borderRadius: 8,
    padding: 15,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 8,
    elevation: 3,
  },
  wishlistHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  wishlistTitleButton: {
    flexDirection: 'row', // Hier hinzugefügt
    alignItems: 'center', // Hier hinzugefügt
    backgroundColor: '#2a9d8f',
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 5,
  },
  icon: {
    marginRight: 5, // Hier hinzugefügt
  },
  wishlistTitle: {
    fontSize: 20,
    color: '#3C3F41',
  },
  deleteButton: {
    backgroundColor: '#E53E3E',
    borderRadius: 50,
    padding: 8,
  },
  itemList: {
    marginTop: 10,
  },
  itemText: {
    fontSize: 18,
    color: '#3C3F41',
  },
  itemRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  removeItemButton: {
    backgroundColor: '#FBD38D',
    borderRadius: 50,
    padding: 8,
  },

});

export default DeineWunschlisten;
