// In RegisterScreen.js

import React, { useState } from 'react';
import { Input, Button, CheckBox } from 'react-native-elements';
import { StyleSheet, Text, View, Linking } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

const RegisterScreen = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [error, setError] = useState('');

  const handleRegister = async () => {
    if (!agreedToTerms) {
      setError('Sie müssen den Datenschutzbestimmungen zustimmen, um sich zu registrieren.');
      return;
    }
    try {
      const response = await fetch('https://www.schindler-ventures.de:3000/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('User registered successfully:', data);
        navigation.navigate('Friends'); // Wenn die Registrierung erfolgreich ist, navigieren Sie zurück zur Anmeldeseite
      } else {
        const errorData = await response.json();
        console.error('Error registering user:', errorData);
      }
    } catch (error) {
      console.error('Error registering user:', error);
    }
  };

  return (
    <View style={styles.container}>
      {error ? <Text style={styles.error}>{error}</Text> : null}
      <Input
        label="E-Mail"
        value={email}
        onChangeText={setEmail}
        autoCapitalize="none"
        keyboardType="email-address"
        inputStyle={styles.input}
        labelStyle={styles.label}
      />
      <Input
        label="Passwort"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
        inputStyle={styles.input}
        labelStyle={styles.label}
      />
      <CheckBox
        title={
          <Text
            style={styles.link}
            onPress={() => Linking.openURL('https://geschenkly.de/datenschutz/')}
          >
            Ich stimme der Datenschutzerklärung zu.
          </Text>
        }
        checked={agreedToTerms}
        onPress={() => setAgreedToTerms(!agreedToTerms)}
        containerStyle={styles.checkboxContainer}
        textStyle={styles.checkboxText}
      />
      <Button
        title="Registrieren"
        onPress={handleRegister}
        buttonStyle={styles.registerButton}
      />
      <Text
        style={styles.impressumLink}
        onPress={() => Linking.openURL('https://geschenkly.de/impressum/')}>
        Impressum
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
    backgroundColor: '#3C3F41',
  },
  text: {
    fontSize: 18,
    color: '#fff',
    marginBottom: 30,
    textAlign: 'center',
  },
  input: {
    color: '#fff',
  },
  label: {
    color: '#fff',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#EC823A',
    marginBottom: 10,
  },
  space: {
    height: 20, // oder wie viel Platz Sie möchten
  },
  impressumLink: {
    color: '#fff',
    textDecorationLine: 'underline',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  link: {
    color: '#fff',
    textDecorationLine: 'underline',
  },
  checkboxContainer: {
    backgroundColor: '#3C3F41',
    borderColor: '#3C3F41',
  },
  checkboxText: {
    color: '#fff',
  },
  error: {
    color: '#ff0000',
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 20,
    fontWeight: 'bold',
  },
  registerButton: {
    backgroundColor: '#f8a978',
    marginBottom: 10,
  },
  forgotPassword: {
    color: '#f8a978', // Farbe des "Passwort vergessen?" Buttons
  },
});


export default RegisterScreen;
