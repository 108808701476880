import React, { useState, useEffect } from 'react';
import { ScrollView, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import getCategories from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/GetCategories.js';

const CategoriesMenuAnlass = ({ onCategorySelected }) => {
  const [mainCategories, setMainCategories] = useState([]);
  const [additionalCategories, setAdditionalCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const navigation = useNavigation();

  useEffect(() => {
    const fetchCategories = async () => {
      const fetchedCategories = await getCategories();
      const mainCategories = fetchedCategories.filter(category => {
        return category.name === 'Geschenke zur Taufe' ||
               category.name === 'Geschenke zur Geburt' ||
               category.name === 'Hochzeitsgeschenke'||
               category.name ===  'Besondere Anlässe' ||
               category.name ===  'Geschenke zum Jahrestag' ||
               category.name ===  'Geschenke zum Jubiläum' ||
               category.name ===  'Geschenke zum Kindergeburtstag' ||
               category.name ===  'Geschenke zum Valentinstag' ||
               category.name ===  'Geschenke zur goldenen Hochzeit' ||
               category.name ===  'Geschenke zur Silberhochzeit' ||
               category.name ===  'Muttertagsgeschenke' ||
               category.name ===  'Nikolausgeschenke' ||
               category.name ===  'Ostergeschenke'
      });
      setMainCategories(mainCategories);

      // Füge hier die Logik für die zusätzlichen Kategorien hinzu
      /*
      const additionalCategories = fetchedCategories.filter(category => {
        return category.name ===  'Besondere Anlässe' ||
               category.name ===  'Geschenke zum Jahrestag' ||
               category.name ===  'Geschenke zum Jubiläum' ||
               category.name ===  'Geschenke zum Kindergeburtstag' ||
               category.name ===  'Geschenke zum Valentinstag' ||
               category.name ===  'Geschenke zur goldenen Hochzeit' ||
               category.name ===  'Geschenke zur Silberhochzeit' ||
               category.name ===  'Muttertagsgeschenke' ||
               category.name ===  'Nikolausgeschenke' ||
               category.name ===  'Ostergeschenke'
      });
      */
      setAdditionalCategories(additionalCategories);
    };
    fetchCategories();
  }, []);

  const handleCategoryPress = (category) => {
    setSelectedCategory(category.id);
    if (onCategorySelected) {
      onCategorySelected(category);
    }
    if (navigation) {
      navigation.navigate('ProductListAnlass', { categoryId: category.id });
    } else {
      console.warn('Navigation prop is not defined. Using default navigation instead.');
    }
  };

  return (
    <ScrollView style={styles.container}>
      {mainCategories.map((category) => (
        <TouchableOpacity
          key={category.id}
          style={[
            styles.categoryItem,
            selectedCategory === category.id ? styles.selectedCategory : null,
          ]}
          onPress={() => handleCategoryPress(category)}
        >
          <Text style={styles.categoryName}>{category.name}</Text>
        </TouchableOpacity>
      ))}
      {additionalCategories.map((category) => (
        <TouchableOpacity
          key={category.id}
          style={[
            styles.additionalCategoryItem,
            selectedCategory === category.id ? styles.selectedCategory : null,
          ]}
          onPress={() => handleCategoryPress(category)}
        >
          <Text style={styles.additionalCategoryName}>{category.name}</Text>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
};
const styles = StyleSheet.create({
  container: {
    maxHeight: '100%',
  },
  categoryItem: {
    padding: 10,
    margin: 5,
    backgroundColor: '#f8f8f8',
    borderRadius: 4,
    borderColor: '#ccc',
    borderWidth: 1,
    alignItems: 'center',
  },
  categoryName: {
    fontSize: 16,
    color: '#333',
  },
  additionalCategoryItem: {
    padding: 10,
    margin: 5,
    backgroundColor: '#f8f8f8',
    borderRadius: 4,
    borderColor: '#ddd',  // Hellere Randfarbe für zusätzliche Kategorien
    borderWidth: 1,
    alignItems: 'center',
  },
  additionalCategoryName: {
    fontSize: 14,  // Kleinere Schriftgröße für zusätzliche Kategorien
    color: '#777',  // Dunklere Schriftfarbe für zusätzliche Kategorien
  },
  selectedCategory: {
    backgroundColor: '#ccc',
  },
});

export default CategoriesMenuAnlass;
