import React, { useState, useContext, useEffect } from 'react';
import { View } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import ProductList from './src/screens/ProductList';
import ProductDetails from './src/screens/ProductDetails';
import Anlass from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/WishList/Anlass.js';
import Friends from './src/screens/Friends';
import SavedProducts from './src/screens/WishList/SavedProducts';
import 'react-native-gesture-handler';
import WishlistCountContext from './src/screens/WishlistCountContext';
import AppNavigator from './src/screens/Navigation/AppNavigator';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import EntryPage from './src/screens/WishList/LoggedIn/EntryPage';
import AuthContext from './src/screens/AuthContext';
import BirthdayReminder from './src/screens/WishList/LoggedIn/BirthdayReminder.js';
import GeschenkeAssistent from './src/screens/WishList/LoggedIn/GeschenkeAssistent';
import DeineWunschlisten from './src/screens/WishList/LoggedIn/DeineWunschlisten';
import WunschlistenDeinerFreunde from './src/screens/WishList/LoggedIn/WunschlistenDeinerFreunde';
import Account from './src/screens/WishList/LoggedIn/Account';
import AlleErinnerungen from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/WishList/LoggedIn/Reminders/AlleErinnerungen.js';
import ErinnerungAnlegen from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/WishList/LoggedIn/Reminders/ErinnerungAnlegen';
import { analytics, logEvent  } from "./firebase";
import ResetPassword from './src/screens/ResetPassword';
import RegisterScreen from './src/screens/RegisterScreen';
import StartScreen from './src/screens/StartScreen';


const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();



const MainStack = () => {
  return (
    <Stack.Navigator initialRouteName="StartScreen">
      <Stack.Screen
        name="StartScreen"
        component={StartScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ProductList"
        component={ProductList}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ProductDetails"
        component={ProductDetails}
        options={{ title: 'Produktdetails' }}
      />
    </Stack.Navigator>
  );
};

const MyListStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="MyLists"
        component={SavedProducts}
        options={{ headerShown: false }} // Verstecken Sie den Header für die MyList-Ansicht
      />
    </Stack.Navigator>
  );
};

const WishlistStack = () => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Wishlist"
        component={Anlass}
        options={{ headerShown: false }} // Verstecken Sie den Header für die Wishlist-Ansicht
      />
    </Stack.Navigator>
  );
};

const shouldShowHeader = (route) => {
  const routeName = route.name;

  if (routeName === 'Friends') {
    return false;
  } else {
    return true;
  }
};

const FriendsStack = () => {
  const { isAuthenticated } = useContext(AuthContext);


  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Root"
        component={isAuthenticated ? EntryPage : Friends}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="ResetPassword"
        component={ResetPassword}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Friends"
        component={Friends}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="RegisterScreen"
        component={RegisterScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="EntryPage"
        component={EntryPage}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="BirthdayReminder"
        component={BirthdayReminder}
        options={({ route }) => ({
          headerShown: shouldShowHeader(route),
          headerTitle: 'Zurück zum Menü'
        })}
      />
      <Stack.Screen
        name="GeschenkeAssistent"
        component={GeschenkeAssistent}
        options={({ route }) => ({
          headerShown: shouldShowHeader(route),
          headerTitle: 'Zurück zum Menü',
        })}
      />
      <Stack.Screen
        name="DeineWunschlisten"
        component={DeineWunschlisten}
        options={({ route }) => ({
          headerShown: shouldShowHeader(route),
          headerTitle: 'Zurück zum Menü'
        })}
      />
      <Stack.Screen
        name="WunschlistenDeinerFreunde"
        component={WunschlistenDeinerFreunde}
        options={({ route }) => ({
          headerShown: shouldShowHeader(route),
        })}
      />
      <Stack.Screen
        name="Account"
        component={Account}
        options={({ route }) => ({
          headerShown: shouldShowHeader(route),
          headerTitle: 'Zurück zum Menü'
        })}
      />

      {/* Füge die neuen Bildschirme hier hinzu */}
      <Stack.Screen
  name="AlleErinnerungen"
  component={AlleErinnerungen}
  options={({ route }) => ({
    headerShown: shouldShowHeader(route),
    headerTitle: 'Zurück zur Übersicht',
  })}
  />
      <Stack.Screen
        name="ErinnerungAnlegen"
        component={ErinnerungAnlegen}
        options={({ route }) => ({
          headerShown: shouldShowHeader(route),
          headerTitle: 'Zurück zur Übersicht',
        })}
      />
    </Stack.Navigator>
  );
};







const App = () => {
  const [wishlistCount, setWishlistCount] = useState(0);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSuccessfulLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
  setIsAuthenticated(false);
};

useEffect(() => {
    logEvent(analytics, 'app_started');
}, []);


  return (
    <AuthContext.Provider value={{ isAuthenticated, handleSuccessfulLogin, handleLogout  }}>
    <WishlistCountContext.Provider value={{ wishlistCount, setWishlistCount }}>
      <NavigationContainer>
        <Tab.Navigator
          screenOptions={({ route }) => ({
            tabBarIcon: ({ focused, color, size }) => {
              let iconName;

              if (route.name === 'Geschenkideen') {
                iconName = focused ? 'gift' : 'gift';
              } else if (route.name === 'Anlass') {
                iconName = focused ? 'calendar' : 'calendar';
              } else if (route.name === 'Deine Wunschliste') {
                iconName = focused ? 'list' : 'list';
              } else if (route.name === 'Konto') {
                iconName = focused ? 'user' : 'user';
              }

              return <FontAwesome name={iconName} size={size} color={color} />;
            },
            tabBarVisible: route.name !== 'Konto',
          })}
          tabBarOptions={{
            activeTintColor: 'tomato',
            inactiveTintColor: 'gray',
          }}
        >
          <Tab.Screen name="Willkommen" component={MainStack} options={{ headerShown: false }} />
          <Tab.Screen name="Anlass" component={AppNavigator} options={{ headerShown: false }} />
          <Tab.Screen name="Deine Wunschliste" component={SavedProducts} options={{ headerShown: false }}/>
          <Tab.Screen name="Konto" component={FriendsStack} options={{ headerShown: false }} />

        </Tab.Navigator>
      </NavigationContainer>
    </WishlistCountContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
