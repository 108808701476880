import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, FlatList, Modal, TouchableOpacity, Linking, Image,  ScrollView } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import jwtDecode from 'jwt-decode';
import FontAwesome from 'react-native-vector-icons/FontAwesome';


// Import WooCommerce API
import WooCommerceCostumApi from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/WooCommerceCostumApi.js';


const AlleErinnerungen = () => {
  const [reminders, setReminders] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [products, setProducts] = useState([]);
  const numColumns = 3; // Erzeugt ein Raster mit 3 Spalten

  const occasionToCategoryMap = {
    "Geburtstag": 184 ,
    "Weihnachten": 155 ,
    "Jahrestag": 26 ,

    //... add other occasions and their corresponding category IDs
  };

  useEffect(() => {
    fetchUserIdAndReminders();
  }, []);

  const fetchUserIdAndReminders = async () => {
    const userId = await getUserIdFromStorage();
    if (userId) {
      fetchReminders(userId);
    }
  };

  const getUserIdFromStorage = async () => {
    try {
      const token = await AsyncStorage.getItem('token');
      if (token) {
        const decoded = jwtDecode(token);
        return decoded.userId;
      }
      return null;
    } catch (error) {
      console.error('Error getting user ID from storage:', error);
      return null;
    }
  };

  const fetchReminders = async (userId) => {
    try {
      const response = await fetch(`https://www.schindler-ventures.de:3000/reminders/${userId}`);
      const data = await response.json();
      setReminders(data);
    } catch (error) {
      console.error('Error fetching reminders:', error);
    }
  };
  const determineAgeCategory = (age) => {
  if (age <= 8) return '165'; // ID für die Kategorie 0-8
  if (age <= 15) return '189'; // ID für die Kategorie 9-15
  if (age <= 20) return '71'; // Platzhalter ID
  if (age <= 29) return '39'; // Platzhalter ID
  if (age <= 39) return '40'; // Platzhalter ID
  if (age <= 59) return '41'; // Platzhalter ID
  return '154'; // Platzhalter ID für die Kategorie 60+
};

const fetchProducts = async (selectedCategoryId, age, gender) => {
console.log("Fetching products...", selectedCategoryId, age, gender, fetchProducts);
try {
  const ageCategory = determineAgeCategory(age);
  console.log('Alter Kategorie', ageCategory)
  const response = await WooCommerceCostumApi.get('my_custom_endpoint', {
    params: {
    category1: ageCategory,
    category2:  selectedCategoryId,// Ersetzen Sie durch den Slug der zweiten gewünschten Kategorie
  },
  });
  setProducts(response.data);
  console.log("API Response:", response.data);
} catch (error) {
  console.log("API Error:", error);
}
};

const deleteReminder = async (id) => {
  try {
    const response = await fetch(`https://www.schindler-ventures.de:3000/reminders/${id}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error('Response not OK');
    }

    const data = await response.json();
    console.log(data.message);
    // Refresh the list of reminders after deletion
    fetchUserIdAndReminders();
  } catch (error) {
    console.error('Error deleting reminder:', error);
  }
};


const [deleteModalVisible, setDeleteModalVisible] = useState(false);
const [deleteItem, setDeleteItem] = useState(null);


return (
  <View style={styles.container}>
    <FlatList
      data={reminders}
      renderItem={({ item }) => (
        <View style={styles.listItem}>
          <TouchableOpacity
            style={styles.listItemDetails}
            onPress={async () => {
              setSelectedItem(item);
              const selectedCategoryId = occasionToCategoryMap[item.occasion];
              const age = item.age;
              const gender = item.gender;
              await fetchProducts(selectedCategoryId, age, gender);
              setModalVisible(true);
            }}
          >
            <Text style={styles.listItemText}>{item.name}</Text>
            <View style={styles.detailsRow}>
              <FontAwesome name="heart" size={24} color="white" />
              <Text style={styles.listItemText}>{item.occasion}</Text>
              <FontAwesome name="calendar" size={24} color="white" />
              <Text style={styles.listItemText}>{item.date}</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
  style={styles.viewGiftButton}
  onPress={async () => {
    setSelectedItem(item);
    const selectedCategoryId = occasionToCategoryMap[item.occasion];
    const age = item.age;
    const gender = item.gender;
    await fetchProducts(selectedCategoryId, age, gender);
    setModalVisible(true);
  }}
>
  <View style={styles.buttonContent}>
    <FontAwesome name="gift" size={24} color="white" />
    <Text style={styles.viewGiftButtonText}>Geschenkvorschläge anschauen</Text>
  </View>
</TouchableOpacity>
<TouchableOpacity
  style={styles.deleteButton}
  onPress={() => {
    setDeleteItem(item._id);
    setDeleteModalVisible(true);
  }}
>
  <FontAwesome name="remove" size={24} color="white" />
</TouchableOpacity>


        </View>
      )}
      keyExtractor={(item) => item._id}
    />
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => {
        setModalVisible(!modalVisible);
      }}
    >
      <View style={styles.modalOverlay}>
        <ScrollView contentContainerStyle={styles.modalView}>
          {selectedItem && (
            <>
              <View style={styles.modalHeader}>
                <Text style={styles.modalText}>Name: {selectedItem.name}</Text>
                <Text style={styles.modalText}>Datum: {selectedItem.date}</Text>
                <Text style={styles.modalText}>Anlass: {selectedItem.occasion}</Text>
              </View>
              <FlatList
                data={products}
                renderItem={({ item }) => (
                  <View style={styles.modalItem}>
                    <Text style={styles.modalItemText}>{item.name}</Text>

                    {item.image ? (
                      <Image
                        source={{ uri: item.image }}
                        style={styles.productImage}
                      />
                    ) : (
                      <Image
                        source={{ uri: 'https://placehold.it/100x100' }}
                        style={styles.productImage}
                      />
                    )}

                    {item.external_url && (
                      <TouchableOpacity
                        style={styles.externalLinkButton}
                        onPress={() => Linking.openURL(item.external_url)}
                      >
                        <Text style={styles.externalLinkButtonText}>Zum Shop</Text>
                      </TouchableOpacity>
                    )}
                  </View>
                )}
                keyExtractor={(item) => item.id.toString()}
                numColumns={numColumns}
              />
              <TouchableOpacity
                style={styles.closeButton}
                onPress={() => {
                  setModalVisible(false);
                  setProducts([]);
                }}
              >
                <Text style={styles.closeButtonText}>Schließen</Text>
              </TouchableOpacity>
            </>
          )}
        </ScrollView>
      </View>
    </Modal>
    <Modal
  animationType="slide"
  transparent={true}
  visible={deleteModalVisible}
  onRequestClose={() => {
    setDeleteModalVisible(false);
    setDeleteItem(null);
  }}
>
  <View style={styles.centeredView}>
    <View style={styles.modalView}>
      <Text style={styles.modalText}>Möchten Sie diese Erinnerung wirklich löschen?</Text>
      <View style={styles.modalButtons}>
        <TouchableOpacity
          style={{ ...styles.openButton, backgroundColor: "#2196F3" }}
          onPress={() => {
            deleteReminder(deleteItem);
            setDeleteModalVisible(!deleteModalVisible);
          }}
        >
          <Text style={styles.textStyle}>Ja</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ ...styles.openButton, backgroundColor: "#2196F3" }}
          onPress={() => {
            setDeleteModalVisible(!deleteModalVisible);
            setDeleteItem(null);
          }}
        >
          <Text style={styles.textStyle}>Nein</Text>
        </TouchableOpacity>
      </View>
    </View>
  </View>
</Modal>

  </View>
);
};


const styles = StyleSheet.create({
  container: {
   flex: 1,
   justifyContent: 'center',
   alignItems: 'center',
   backgroundColor: '#3C3F41',
 },
 text: {
   fontSize: 36,
   color: '#fff',
   marginBottom: 30,
   fontFamily: 'Roboto',
 },
 iconContainer: {
   flexDirection: 'row',
   justifyContent: 'space-around',
   marginTop: 20,
 },
 button: {
   flexDirection: 'row',
   justifyContent: 'space-between',
   alignItems: 'center',
   width: '100%',
   padding: 15,
   backgroundColor: '#fff',
   marginBottom: 20,
   borderRadius: 10,
   shadowColor: '#000',
   shadowOffset: { width: 0, height: 2 },
   shadowOpacity: 0.25,
   shadowRadius: 3.84,
   elevation: 5,
   alignItems: 'center',
   justifyContent: 'center',
   marginHorizontal: 10,
 },
 iconText: {
   fontSize: 18,
   textAlign: 'center',
   marginTop: 10,
   color: '#fff',
   fontFamily: 'Roboto',
 },
 listItem: {
   backgroundColor: '#EC823A',
   padding: 15,
   marginBottom: 20,
   borderRadius: 10,
   shadowColor: '#000',
   shadowOffset: { width: 0, height: 2 },
   shadowOpacity: 0.25,
   shadowRadius: 3.84,
   elevation: 5,
 },
 listItemTextName: {
   fontSize: 22,
   color: '#fff',
   fontFamily: 'Roboto',
 },
 deleteButton: {
  position: 'absolute', // Positionieren Sie den Button absolut im Elternelement
  top: 10, // Platzieren Sie den Button 10 Einheiten vom oberen Rand des Elternelements
  right: 10, // Platzieren Sie den Button 10 Einheiten vom rechten Rand des Elternelements
  backgroundColor: '#ff0000', // Roter Hintergrund
  borderRadius: 5, // Abgerundete Ecken
  padding: 10, // Etwas Polsterung um das Symbol herum
  justifyContent: 'center', // Vertikal zentrieren
  alignItems: 'center', // Horizontal zentrieren
},

 listItemTextDetails: {
   fontSize: 14,
   color: '#ddd',
   fontFamily: 'Roboto',
 },
 viewGiftSuggestionsButton: {
   backgroundColor: '#EC823A',
   paddingHorizontal: 20,
   paddingVertical: 10,
   borderRadius: 10,
   marginTop: 10,
 },
 viewGiftSuggestionsButtonText: {
   fontSize: 14,
   color: '#FFFFFF',
   fontFamily: 'Roboto',
 },
 viewGiftButton: {
  flexDirection: 'row', // adds flexibility to layout children horizontally
  alignItems: 'center', // vertically centers the icon and text
  justifyContent: 'center', // horizontally centers the icon and text
  backgroundColor: '#FDBB2D',
  borderRadius: 5,
  padding: 10,
  marginTop: 10,
  marginLeft: 10,
  marginRight: 10,
},
buttonContent: {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
},
viewGiftButtonText: {
  color: 'white',
  fontSize: 16,
  marginLeft: 10, // add some spacing between the icon and the text
},

  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
   backgroundColor: '#FFF',
   borderRadius: 20,
   padding: 20,
   marginTop: '30%',
   marginLeft: '5%',
   marginRight: '5%',
   marginBottom: '10%',
   justifyContent: 'space-between',
   alignItems: 'center',
   shadowColor: '#000',
   shadowOffset: {
     width: 0,
     height: 2,
   },
   shadowOpacity: 0.25,
   shadowRadius: 3.84,
   elevation: 5,
 },
 modalText: {
   marginBottom: 15,
   textAlign: 'center',
   color: '#333',
   fontSize: 20,
   fontFamily: 'Roboto',
 },
 modalItem: {
    flexDirection: 'column',
    margin: 10,
    backgroundColor: '#f9f9f9',
    borderRadius: 10,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: 150,
    height: 200,
    flex: 1, // Hinzugefügt
  },

  productImage: {
    width: 100,
    height: 100,
    borderRadius: 10,
    resizeMode: 'cover', // Hinzugefügt
  },
 externalLinkButton: {
   backgroundColor: '#EC823A',
   paddingHorizontal: 20,
   paddingVertical: 10,
   borderRadius: 10,
   marginTop: 10,
   marginLeft: 'auto',
 },
 externalLinkButtonText: {
   fontSize: 14,
   color: '#FFFFFF',
   fontFamily: 'Roboto',
 },
 centeredView: {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  marginTop: 22
},
modalView: {
  margin: 20,
  backgroundColor: "white",
  borderRadius: 20,
  padding: 35,
  alignItems: "center",
  shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 2
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  elevation: 5
},
openButton: {
  backgroundColor: "#F194FF",
  borderRadius: 20,
  padding: 10,
  elevation: 2,
  margin: 10
},
textStyle: {
  color: "white",
  fontWeight: "bold",
  textAlign: "center"
},
modalText: {
  marginBottom: 15,
  textAlign: "center"
},
modalButtons: {
  flexDirection: 'row'
}

});



export default AlleErinnerungen;
