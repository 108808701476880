import React, { useContext } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import WishlistCountContext from '../screens/WishlistCountContext';
import AuthContext from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/AuthContext.js';

const Header = ({ navigation, onLoginPress, onWishlistPress }) => {
  const { wishlistCount } = useContext(WishlistCountContext);
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <View style={styles.header}>
      <Text style={styles.headerTitle}>Geschenkly </Text>
      <TouchableOpacity
        style={styles.loginButton}
        onPress={() => navigation.navigate('Konto')}
      >
        {isAuthenticated ? (
          <Text style={styles.loginButtonText}>
            Nutze das Herz um Geschenk zur Wunschliste hinzuzufügen
          </Text>
        ) : (
          <Text style={styles.loginButtonText}>Geschenkly Plus Login / Anmelden</Text>
        )}
      </TouchableOpacity>
      <TouchableOpacity onPress={onWishlistPress}>
        <View style={styles.wishlistCountContainer}>
          <Text style={styles.wishlistCountText}>{wishlistCount}</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#3f7ea3',
  },
  headerTitle: {
    fontSize: 24,
    color: '#fff',
  },
  loginButton: {
    backgroundColor: '#fff',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 5,
  },
  loginButtonText: {
    color: '#3f7ea3',
    fontSize: 16,
  },
  wishlistCountContainer: {
    backgroundColor: '#fff',
    borderRadius: 50,
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
  },
  wishlistCountText: {
    color: '#3f7ea3',
    fontSize: 14,
    fontWeight: 'bold',
  },
});

export default Header;
