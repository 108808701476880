import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Share,
  TextInput,
  Modal,
  Dimensions,
  Button,
  Image,
  ScrollView,
  Linking
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFocusEffect } from '@react-navigation/native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { useNavigation } from '@react-navigation/native';
import { useContext } from 'react';
import AuthContext from '../AuthContext';
import jwtDecode from 'jwt-decode';
import image1 from '../images/3F350B1C-1A1D-4813-BB3B-7E67BAC0F385.jpeg';
import image2 from '../images/AD84BD6B-BCB5-4B4C-919B-B3AA17CB5FC7.jpeg';
import image3 from '../images/CatAmazon.jpeg';
import image4 from '../images/4308ACDB-AAD1-4F8D-A006-A84C2835A306.jpeg';
import image5 from '../images/01048F78-2690-42A6-B724-BAF85A49CF1D.jpeg';
import { analytics, logEvent  } from "/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/firebase";

const generateRandomPassword = (length = 6) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const handleExternalLinkPress = async (externalLink) => {
  await logEvent('external_link_clicked', {
    id: externalLink,
    name: 'external_link',
    screen: 'ProductList',
    purpose: 'User clicked on external link in Wishlist',
  });

  Linking.openURL(externalLink);
};

const AdditionalTextModalComponent = ({ additionalTextModalVisible, setAdditionalTextModalVisible, additionalText, setAdditionalText }) => {
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={additionalTextModalVisible}
      onRequestClose={() => {
        setAdditionalTextModalVisible(!additionalTextModalVisible);
      }}
    >
      <View style={styles.centeredView}>
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={styles.modalView}>
            <TextInput
              style={styles.inputStyle}
              placeholder="Schicke eine kleine Nachricht mit"
              value={additionalText}
              onChangeText={setAdditionalText}
              autoFocus
            />
            <Button title="Nachricht speichern und schließen" onPress={() => { setAdditionalTextModalVisible(false) }} />
          </View>
        </ScrollView>
      </View>
    </Modal>
  )
}



const SavedProducts = () => {
  const [savedItems, setSavedItems] = useState([]);
  const [savedNote, setSavedNote] = useState('');
  const [additionalText, setAdditionalText] = useState('');
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [showAddProductForm, setShowAddProductForm] = useState(false);
  const [newProductName, setNewProductName] = useState('');
  const [newProductPrice, setNewProductPrice] = useState('');
  const [products, setProducts] = useState([]);
  const [newProductExternalUrl, setNewProductExternalUrl] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [passwordWishList, setPasswordWishList] = useState(generateRandomPassword());
  const navigation = useNavigation();
  const { isAuthenticated, user } = useContext(AuthContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [occasion, setOccasion] = useState('');
  const [occasionDate, setOccasionDate] = useState('');
  const [occasionTime, setOccasionTime] = useState('');
  const [occasionMessage, setOccasionMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [additionalTextModalVisible, setAdditionalTextModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [passwordInput, setPasswordInput] = useState(passwordWishList);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);



  const navigateToBox3 = () => {
    setShowAddProductForm(false);
    navigation.navigate('Anlass', { screen: 'Box3', initial: false, })
  };

  const navigateToBox1 = () => {
    setShowAddProductForm(false);
    navigation.navigate('Anlass', { screen: 'Box1', initial: false, })
  };




  const addProduct = async () => {
    const newProduct = {
      name: newProductName,
      external_url: newProductExternalUrl, // Speichern der externen URL im Produkt-Objekt
    };
    const updatedItems = [...savedItems, newProduct];
    setSavedItems(updatedItems);
    setNewProductName('');
    setNewProductExternalUrl('');
    setShowAddProductForm(false);
    await AsyncStorage.setItem('selectedProducts', JSON.stringify(updatedItems));
  };

  const addOccasion = async () => {
    await AsyncStorage.setItem('occasion', occasion);
  };

  const saveOccasion = async () => {
    const newOccasion = {
      occasion: occasion,
      date: occasionDate,
      time: occasionTime,
      message: occasionMessage,
    };
    await AsyncStorage.setItem('occasion', JSON.stringify(newOccasion));
  };

  useFocusEffect(
    React.useCallback(() => {
      const getSavedData = async () => {
        try {
          const storedSelectedProducts = await AsyncStorage.getItem('selectedProducts');
          const storedNote = await AsyncStorage.getItem('note');
          const storedOccasion = await AsyncStorage.getItem('occasion'); // Laden des Anlasses aus dem Speicher
          if (storedSelectedProducts) {
            setSavedItems(JSON.parse(storedSelectedProducts));
          }
          if (storedNote) {
            setSavedNote(storedNote);
          }
          if (storedOccasion) {
            const parsedOccasion = JSON.parse(storedOccasion);
            setOccasion(parsedOccasion.occasion); // Setzen des Anlasses aus dem Speicher
            setOccasionDate(parsedOccasion.date); // Setzen des Datums aus dem Speicher
            setOccasionTime(parsedOccasion.time);
            setOccasionMessage(parsedOccasion.occasionMessage); // Setzen der Zeit aus dem Speicher
          }
        } catch (error) {
          console.log(error);
        }
      };

      getSavedData();
    }, []),
  );


  const handleNoteChange = async (newNote) => {
    setSavedNote(newNote);
    try {
      await AsyncStorage.setItem('note', newNote);
      await AsyncStorage.setItem('passwordWishList', passwordWishList); // Speichern Sie die Notiz im lokalen Speicher
    } catch (error) {
      console.log(error);
    }
  };

  const removeItem = async (index) => {
    const updatedItems = savedItems.filter((_, itemIndex) => itemIndex !== index);
    setSavedItems(updatedItems);
    await AsyncStorage.setItem('selectedProducts', JSON.stringify(updatedItems));
  };
  const [showAdditionalText, setShowAdditionalText] = useState(false);
  const [showInfoTextModal, setShowInfoTextModal] = useState(false);


  const renderItem = ({ item, index }) => {
    const title = item.name || item.title;
    const firstProductImage = item.images && item.images[0] ? item.images[0].src : null || item.imageUrl

    return (
  <View style={styles.productItem}>

  <TouchableOpacity onPress={() => handleExternalLinkPress(item.external_url ?? item.DetailPageURL)}>
<View style={{ flexDirection: 'row', alignItems: 'center' }}>
  <Image
    source={{ uri: firstProductImage }}
    style={styles.productImage}
    resizeMode="contain"
  />
  <View style={styles.productInfo}>
    <Text style={styles.productTitle}>{title}</Text>
    <Text style={styles.productPrice}>{item.price}</Text>
  </View>
</View>
</TouchableOpacity>
    <TouchableOpacity
      style={styles.removeButton}
      onPress={() => removeItem(index)}
    >
      <FontAwesome name="trash-o" size={20} color="#fff" />
    </TouchableOpacity>
  </View>
);
  };
  const renderFooter = () => {
    return <View />;
  };



  const shareAndSaveWishlist = async () => {
    try {
      await saveDataToServer(); // Speichern Sie die Daten zuerst auf dem Server

      const result = await Share.share({
        message: `Persönliche Nachricht: ${additionalText}\nPasswort: ${passwordWishList}\nLink zur Webseite: https://geschenkly.de/WishList`,
      });

      if (result.action === Share.sharedAction) {
        console.log('Wishlist shared');
      } else if (result.action === Share.dismissedAction) {
        console.log('Sharing cancelled');
      }
    } catch (error) {
      console.log('Error sharing:', error);
    }
  };

  const saveDataToServer = async () => {
    try {
      // Get the token from AsyncStorage
      const token = await AsyncStorage.getItem('token');

      // Decode the token to get the user ID
      let userId = null;
      if (token) {
        const decoded = jwtDecode(token);
        userId = decoded.userId;
      }

      const response = await fetch('https://www.schindler-ventures.de:3000/saved-items/', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          savedItems,
          savedNote,
          additionalText,
          passwordWishList,
          occasion: {
            name: occasion,
            date: occasionDate,
            time: occasionTime,
            message: occasionMessage,

          },
          userId, // Set the user ID from the token
        }),
      });

      const json = await response.json();
      console.log('Server response:', json);
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error sending data to server:', error);
    }
  };

  const BottomBar = () => (
    <View style={styles.bottomBarContainer}>
      <TouchableOpacity
        style={styles.bottomBarButton}
        onPress={navigateToBox1}
      >
        <FontAwesome name="calendar" size={24} color="#fff" />
      </TouchableOpacity>

      <TouchableOpacity
        style={styles.bottomBarButton}
        onPress={() => setShowAddProductForm(true)}
      >
        <FontAwesome name="plus" size={24} color="#fff" />
      </TouchableOpacity>
      <TouchableOpacity
        style={styles.bottomBarButton}
        onPress={navigateToBox3}
      >
        <FontAwesome name="amazon" size={24} color="#fff" />
      </TouchableOpacity>

      <TouchableOpacity
        style={styles.bottomBarButton}
        onPress={() => setAdditionalTextModalVisible(true)}
      >
        <FontAwesome name="envelope" size={24} color="#fff" />
      </TouchableOpacity>



      <TouchableOpacity
        style={styles.bottomBarButton}
        onPress={() => {
          setPasswordInput(passwordWishList);
          setShowPasswordInput(true);
        }}
      >
        <FontAwesome name="lock" size={24} color="#fff" />
      </TouchableOpacity>
    </View>
  );


  return (
    <View style={styles.container}>

      <TouchableOpacity
        style={styles.infoIconContainer}
        onPress={() => setShowInfoTextModal(true)}
      >
        <FontAwesome name="info-circle" size={24} color="#333" />
      </TouchableOpacity>
      <View style={styles.noteInputContainer}>
        <TextInput
          style={styles.noteInput}
          onChangeText={handleNoteChange}
          value={savedNote}
          placeholder="Meine Wunschliste"
        />
        <TouchableOpacity style={styles.editIconContainer} onPress={() => console.log('Edit icon pressed')}>
          <FontAwesome name="edit" size={24} color="#333" />
        </TouchableOpacity>

      </View>
      {savedItems.length > 0 ? (
        <FlatList
          data={savedItems}
          renderItem={renderItem}
          keyExtractor={(item, index) => index.toString()}
          ListFooterComponent={renderFooter}
        />
      ) : (
        <Text style={styles.message}>Keine gespeicherten Produkte. Nutze das Herz-Symbol um Geschenke zur Wunschliste hinzuzufügen.</Text>
      )}


      <BottomBar />
      <TouchableOpacity style={styles.shareButton} onPress={shareAndSaveWishlist}>
        <Text style={styles.shareButtonText}>Wishlist speichern & teilen</Text>
      </TouchableOpacity>
      {showSuccessModal && (
  <Modal
    animationType="slide"
    transparent={true}
    visible={showSuccessModal}
    onRequestClose={() => {
      setShowSuccessModal(false);
    }}
  >
    <View style={styles.centeredView}>
      <View style={styles.modalView}>
        <Text style={styles.modalText}>
          Deine Wunschliste wurde erfolgreich gespeichert.
          Deine Freunde können deine Wunschliste unter wuensche.geschenkly.app sehen.
          Teilen Ihnen dein Kennwort für Deine Wunschliste mit: {passwordWishList}

        </Text>
        <Button
          title="Schließen"
          onPress={() => {
            setShowSuccessModal(false);
          }}
        />
      </View>
    </View>
  </Modal>
)}


      <Modal
        visible={showAddProductForm}
        animationType="slide"
        transparent={true}
        onRequestClose={() => setShowAddProductForm(false)}
      >
        <View style={styles.centeredView}>
          <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
            <View style={styles.modalView}>

              <TouchableOpacity
                style={styles.closeButton}
                onPress={() => setShowAddProductForm(false)}
              >
                <Text style={styles.closeButtonText}>X</Text>
              </TouchableOpacity>

              <Text style={styles.infoBoxText}>
                Hier kannst du Geschenkideen und Links zu Produkten eingeben, die du woanders gesehen hasst
              </Text>

              <View style={styles.formContainer}>
                <TextInput
                  style={styles.inputStyle}
                  onChangeText={(text) => setNewProductName(text)}
                  value={newProductName}
                  placeholder="Produktname"
                />
                <TextInput
                  style={styles.inputStyle}
                  onChangeText={(text) => setNewProductPrice(text)}
                  value={newProductPrice}
                  placeholder="Preis"
                  keyboardType="numeric"
                />
                <TextInput
                  style={styles.inputStyle}
                  onChangeText={(text) => setNewProductExternalUrl(text)}
                  value={newProductExternalUrl}
                  placeholder="Link"
                />
                <TouchableOpacity style={styles.saveButton} onPress={() => addProduct()}>
                  <Text style={styles.saveButtonText}>Hinzufügen</Text>
                </TouchableOpacity>

              </View>
            </View>
          </ScrollView>
        </View>
      </Modal>

      <Modal visible={showInfoTextModal} animationType="slide">
        <View style={styles.modalContainer}>
          <View style={styles.modalHeader}>
            <TouchableOpacity onPress={() => setShowInfoTextModal(false)}>
              <FontAwesome name="close" size={24} color="#333" />
            </TouchableOpacity>
          </View>
          <View style={styles.infoTextContainer}>
            <Text style={styles.infoText}>
              Hier kannst du eine Liste deiner Wünsche anlegen und mit Deinen Freunden / Gästen teilen.
              Gekaufte Geschenke können marktiert werden um doppelte Geschenke zu vermeiden.

            </Text>
          </View>
        </View>
      </Modal>
      <AdditionalTextModalComponent
        additionalTextModalVisible={additionalTextModalVisible}
        setAdditionalTextModalVisible={setAdditionalTextModalVisible}
        additionalText={additionalText}
        setAdditionalText={setAdditionalText}
      />



      {showPasswordInput && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={showPasswordInput}
          onRequestClose={() => {
            setShowPasswordInput(false);
          }}
        >
          <View style={styles.centeredView}>
            <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
              <View style={styles.modalView}>
              <TouchableOpacity onPress={() => setShowPasswordInput(false)}>
                <FontAwesome name="close" size={24} color="#333" />
              </TouchableOpacity>

                <Text style={styles.infoText}>
                  Mit diesem Passwort kann deine Wunschlist aufgerufen werden. Du kannst das Passwort ändern:
                </Text>
                <TextInput
                  style={styles.additionalText}
                  onChangeText={(text) => setPasswordInput(text)}
                  value={passwordInput}
                  autoFocus
                  placeholder="gebe ein einfaches Passwort ein, so dass deine Freunde die Liste aufrufen können"
                />
                <Button
                  title="Passwort speichern und schließen"
                  onPress={() => {
                    setPasswordWishList(passwordInput);
                    setShowPasswordInput(false);
                  }}
                />
              </View>
            </ScrollView>
          </View>
        </Modal>
      )}

    </View>
  );
};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding: 10,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  inputStyle: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    marginTop: 10,
    borderRadius: 5,
    padding: 10,
    width: '100%',
  },
  imageContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  imageStyle: {
    width: 150,
    height: 150,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  productItem: {
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  margin: 10,
  backgroundColor: '#fff',
  borderRadius: 5,
  padding: 10,
  elevation: 3,
},
productImage: {
  width: 60,
  height: 60,
},
productInfo: {
  flexDirection: 'column',
  flex: 1,
  marginLeft: 10,
},
productTitle: {
  fontSize: 18,
  fontWeight: 'bold',
},
productPrice: {
  fontSize: 16,
  color: '#666',
},
  removeButton: {
    backgroundColor: '#ff0000',
    padding: 10,
    borderRadius: 5,
  },
  bottomBarContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    backgroundColor: '#eee',
  },
  bottomBarButton: {
    padding: 10,
    backgroundColor: '#008CBA',
    borderRadius: 5,
  },
  shareButton: {
    backgroundColor: '#4CAF50',
    padding: 10,
    alignItems: 'center',
    borderRadius: 5,
    margin: 10,
  },
  shareButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  noteInputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    padding: 10,
  },
  noteInput: {
    flex: 1,
    fontSize: 18,
  },
  editIconContainer: {
    marginLeft: 10,
  },
  message: {
    marginTop: 30,
    textAlign: 'center',
    fontSize: 18,
    color: '#888',
  },
  saveButton: {
  backgroundColor: '#008CBA',
  padding: 10,
  borderRadius: 5,
  alignItems: 'center',
  marginTop: 10,
},
saveButtonText: {
  color: '#fff',
  fontSize: 16,
  fontWeight: 'bold',
},
});



export default SavedProducts;
