import React, { useState, useEffect } from 'react';
import { ScrollView, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import getCategories from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/GetCategories.js';
const CategoriesMenu = ({ onCategorySelected }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const navigation = useNavigation();

  useEffect(() => {
    const fetchCategories = async () => {
      const fetchedCategories = await getCategories();
      console.log('Kategorien', fetchedCategories)
      const filteredCategories = fetchedCategories.filter(category => {
        return category.name === 'Ausgefallene Geschenke' ||
               category.name === 'Geschenke für Freunde' ||
               category.name === 'Geschenke für Mama' ||
               category.name === 'Geschenke für Männer'||
               category.name === 'Geschenke für deine Partnerin'||
               category.name === 'Geschenke für deinen Partner'||
               category.name === 'Geschenke für die Großeltern'||
               category.name === 'Geschenke für Frauen'||
               category.name === 'Geschenk für den Bruder'||
               category.name === 'Geschenk für die Schwester'||
               category.name === 'Geschenke für die Großeltern'||
               category.name === 'Geschenke für eine Freundin'||
               category.name === 'Geschenke für Eltern'||
               category.name === 'Geschenke für Freunde'||
               category.name === 'Geschenke für Kinder'



      });
      setCategories(filteredCategories);
    };
    fetchCategories();
  }, []);

  const handleCategoryPress = (category) => {
    setSelectedCategory(category.id);
    if (onCategorySelected) {
      onCategorySelected(category);
    }
    if (navigation) {
      navigation.navigate('ProductList', { categoryId: category.id });
    } else {
      console.warn('Navigation prop is not defined. Using default navigation instead.');
    }
  };


  return (
    <ScrollView style={styles.container}>
      {categories.map((category) => (
        <TouchableOpacity
          key={category.id}
          style={[
            styles.categoryItem,
            selectedCategory === category.id ? styles.selectedCategory : null,
          ]}
          onPress={() => handleCategoryPress(category)}
        >
          <Text style={styles.categoryName}>{category.name}</Text>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    maxHeight: '100%',
  },
  categoryItem: {
    padding: 10,
    margin: 5,
    backgroundColor: '#f8f8f8',
    borderRadius: 4,
    borderColor: '#ccc',
    borderWidth: 1,
    alignItems: 'center',
  },
  categoryName: {
    fontSize: 16,
    color: '#333',
  },
  selectedCategory: {
    backgroundColor: '#ccc',
  },
});

export default CategoriesMenu;
