import React, { useState, useEffect, useContext, useCallback } from 'react';
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  Linking,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import WooCommerceApi from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/WooCommerceApi.js';
import CategoriesMenue from './CategoriesMenue';
import WishlistCountContext from '../screens/WishlistCountContext';
import { NavigationContainer, useFocusEffect } from '@react-navigation/native';
import Header from './Header';
import { analytics, logEvent  } from "/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/firebase";

const ProductList = ({ navigation, route }) => {
  const categoryId = route.params?.categoryId || null;
  const [products, setProducts] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(categoryId);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { wishlistCount, setWishlistCount } = useContext(WishlistCountContext);
  const navigateToWishlist = () => {
    navigation.navigate('Deine Wunschliste');
  };

  useEffect(() => {
    fetchProducts();
    loadSelectedProducts();
  }, [selectedCategoryId]);

  useEffect(() => {
    setWishlistCount(selectedProducts.length);
  }, [selectedProducts]);

  useEffect(() => {
    navigation.setParams({ wishlistCount });
  }, [wishlistCount]);

  const updateWishlistCount = useCallback(() => {
    navigation.setParams({ wishlistCount });
  }, [wishlistCount]);

  useFocusEffect(updateWishlistCount);

  useEffect(() => {
    navigation.setOptions({
        title: 'Geschenkideen',
    });
}, [navigation]);

  const handleExternalLinkPress = async (externalLink) => {
    await logEvent('external_link_clicked', {
        id: externalLink,
        name: 'external_link',
        screen: 'ProductList',
        purpose: 'User clicked on external link',
    });

    Linking.openURL(externalLink);
};



  const fetchProducts = async () => {
    try {
      const response = await WooCommerceApi.get('products', {
        params: {
          per_page: 35,
          category: selectedCategoryId,
        },
      });
      setProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const loadMoreProducts = async () => {
    try {
      const response = await WooCommerceApi.get('products', {
        params: {
          per_page: 20,
          category: selectedCategoryId,
        },
      });
      const additionalProducts = response.data;
      setProducts((prevProducts) => [...prevProducts, ...additionalProducts]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const loadSelectedProducts = async () => {
    const storedSelectedProducts = await AsyncStorage.getItem('selectedProducts');
    if (storedSelectedProducts) {
      setSelectedProducts(JSON.parse(storedSelectedProducts));
    }
  };

  const handleHeartPress = async (product) => {
    const updatedSelectedProducts = selectedProducts.some(
      (selectedProduct) => selectedProduct.id === product.id
    )
      ? selectedProducts.filter(
          (selectedProduct) => selectedProduct.id !== product.id
        )
      : [...selectedProducts, product];

    setSelectedProducts(updatedSelectedProducts);
    await AsyncStorage.setItem(
      'selectedProducts',
      JSON.stringify(updatedSelectedProducts)
    );
  };

  const renderItem = ({ item }) => {
    const isHeartSelected = selectedProducts.some(
      (selectedProduct) => selectedProduct.id === item.id
    );
    const firstProductImage = item.images && item.images[0] ? item.images[0].src : null;

    return (
      <View style={styles.productItem}>
        <Text style={styles.productTitle}>{item.name}</Text>
        <Image
          source={{
            uri: firstProductImage,
          }}
          style={styles.productImage}
        />
        <TouchableOpacity
          style={styles.heartIcon}
          onPress={() => handleHeartPress(item)}
        >
          <FontAwesome
            name={isHeartSelected ? 'heart' : 'heart-o'}
            size={24}
            color={isHeartSelected ? 'red' : 'black'}
          />
        </TouchableOpacity>
        <TouchableOpacity
  style={styles.externalLinkButton}
  onPress={() => handleExternalLinkPress(item.external_url)}
>
  <FontAwesome name="external-link" size={18} color="#fff" />
  <Text style={styles.externalLinkText}>Zum Shop</Text>
</TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <Header
        navigation={navigation}
        onLoginPress={() => navigation.navigate('Login')}
        onWishlistPress={navigateToWishlist}
      />
      <View style={styles.content}>
        <View style={styles.menu}>
          <CategoriesMenue onCategorySelected={(category) => setSelectedCategoryId(category.id)} />
        </View>
        <View style={styles.products}>
          <FlatList
            data={products}
            renderItem={renderItem}
            keyExtractor={(item, index) => (item && item.id ? item.id.toString() : `default-key-${index}`)}
            numColumns={2}
            onEndReached={loadMoreProducts}
            onEndReachedThreshold={0.1}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
      flex: 1,
      padding: 10,
    },
    content: {
      flex: 1,
      flexDirection: 'row',
    },
    menu: {
      width: '30%',
    },
    products: {
      width: '70%',
    },
productItem: {
flex: 1,
margin: 5,
backgroundColor: '#f8f8f8',
padding: 10,
},
productTitle: {
fontSize: 16,
fontWeight: 'bold',
marginTop: 5,
},
errorMessage: {
fontSize: 18,
textAlign: 'center',
marginTop: 20,
color: 'red',
},
productPrice: {
fontSize: 14,
color: '#333',
marginTop: 5,
},
productImage: {
width: '100%',
height: 200,
resizeMode: 'contain',
},
heartIcon: {
position: 'absolute',
top: 5,
right: 10,
},
externalLinkButton: {
flexDirection: 'row',
alignItems: 'center',
justifyContent: 'center',
backgroundColor: '#3f7ea3',
paddingVertical: 10,
paddingHorizontal: 20,
borderRadius: 5,
marginTop: 10,
},
externalLinkText: {
color: '#fff',
fontSize: 18,
marginLeft: 5,
},
});

export default ProductList;
