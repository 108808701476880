import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  FlatList,
  Image,
  ScrollView,
  Linking,
  StyleSheet,
} from 'react-native';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import WishlistCountContext from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/WishlistCountContext.js';
import { NavigationContainer, useFocusEffect } from '@react-navigation/native';
import Header from '../Header';
import { useNavigation } from '@react-navigation/native';

const Box3 = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const navigation = useNavigation();
  const navigateToWishlist = () => {
    navigation.navigate('Deine Wunschliste');
  };

  useEffect(() => {
    getSelectedProducts();
  }, []);

  const getSelectedProducts = async () => {
    try {
      const storedSelectedProducts = await AsyncStorage.getItem('selectedProducts');
      if (storedSelectedProducts) {
        setSelectedProducts(JSON.parse(storedSelectedProducts));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleHeartPress = async (product) => {
    const updatedSelectedProducts = selectedProducts.some(
      (selectedProduct) => selectedProduct.ASIN === product.ASIN
    )
      ? selectedProducts.filter(
          (selectedProduct) => selectedProduct.ASIN !== product.ASIN
        )
      : [...selectedProducts, product];

    setSelectedProducts(updatedSelectedProducts);
    await AsyncStorage.setItem(
      'selectedProducts',
      JSON.stringify(updatedSelectedProducts)
    );
  };

  const handleSearch = async () => {
  try {
    const response = await axios.post('https://www.schindler-ventures.de:3000/search-amazon', { q: searchQuery });
    console.log('Response data:', response.data);

    if (response.data && Array.isArray(response.data)) {
      const productList = response.data.map(productResponse => {
        if (productResponse.ItemInfo && productResponse.ItemInfo.Title) {
          return {
            ...productResponse,
            title: productResponse.ItemInfo.Title.DisplayValue,
            price: productResponse.Offers.Listings[0].Price.DisplayAmount,
            imageUrl: productResponse.Images.Primary.Medium.URL,
            detailPageURL: productResponse.DetailPageURL,
          };
        }
        return null;
      }).filter(item => item !== null);

      console.log('Produktformat:', productList);
      setProducts(productList);
    } else {
      console.error("Response data is undefined or doesn't have the expected structure.");
    }
  } catch (error) {
    console.error('Error calling Express server:', error);
  }
};

const renderItem = ({ item }) => {
  const isHeartSelected = selectedProducts.some(
    (selectedProduct) => selectedProduct.ASIN === item.ASIN
  );

  return (
    <View style={styles.productItem}>
      <Text style={styles.productTitle}>{item.title}</Text>
      <Image
        source={{ uri: item.imageUrl }}
        style={styles.productImage}
        resizeMode="contain"
      />
      <TouchableOpacity
        style={styles.productLinkButton}
        onPress={() => Linking.openURL(item.detailPageURL)}
      >
        <Text style={styles.productLinkButtonText}>zu Amazon</Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => handleHeartPress(item)}
        style={styles.heartIcon}
      >
        <FontAwesome
          name={isHeartSelected ? 'heart' : 'heart-o'}
          size={24}
          color={isHeartSelected ? 'red' : 'black'}
        />
      </TouchableOpacity>
    </View>
  );
};



return (
  <View style={styles.container}>
  <Header
    navigation={navigation}
    onLoginPress={() => navigation.navigate('Login')}
    onWishlistPress={navigateToWishlist}
  />
    <TextInput
      style={styles.searchInput}
      value={searchQuery}
      onChangeText={setSearchQuery}
      placeholder="hier Suchen"
    />
    <TouchableOpacity onPress={handleSearch} style={styles.searchButton}>
      <Text style={styles.searchButtonText}>Suchen</Text>
    </TouchableOpacity>
    <View style={styles.productsListContainer}>
      <FlatList
        data={products}
        renderItem={renderItem}
        keyExtractor={(item) => item.ASIN}
        numColumns={2}
      />
    </View>
  </View>
);
};

const styles = StyleSheet.create({
  container: {
      flex: 1,
      padding: 10,
    },
    content: {
      flex: 1,
      flexDirection: 'row',
    },
    menu: {
      width: '30%',
    },
    products: {
      width: '70%',
    },
    searchInput: {
    height: 60,
    borderColor: '#d6d7da',
    borderWidth: 1,
    borderRadius: 20,  // Macht die Ecken des Eingabefelds rund
    paddingLeft: 20,  // Fügt etwas Platz auf der linken Seite des Texts hinzu
    marginBottom: 20,
    alignSelf: 'center', // Zentriert die Suchbox horizontal
    marginTop: 20, // Fügt Abstand nach oben hinzu
    backgroundColor: '#fff', // Weißer Hintergrund
    width: '60%', // Macht das Eingabefeld weniger breit
    shadowColor: "#000", // Fügt einen Schatten hinzu, um einen "erhöhten" Effekt zu erzeugen
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },

  searchButton: {
    backgroundColor: '#3f7ea3',
    padding: 10,
    borderRadius: 20,  // Macht die Ecken des Buttons rund
    width: '60%',  // Macht den Button weniger breit als die Suchbox
    alignSelf: 'center',  // Zentriert den Button horizontal
    marginTop: 20,  // Fügt Abstand nach oben hinzu
    alignItems: 'center',
  },

  searchButtonText: {
    color: '#fff',
    fontSize: 20,  // Erhöht die Schriftgröße
  },
  productsListContainer: {
    width: '90%',
    height: '80%',
  },
  productItem: {
    backgroundColor: '#FFFFFF',
    padding: 16,
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
    width: '48%',
    marginHorizontal: '1%',
    minHeight: 130,
  },
  productTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333',
    marginBottom: 5,
  },
  productPrice: {
    fontSize: 14,
    color: '#333',
  },
  productImage: {
    width: 60,
    height: 60,
    marginRight: 10,
  },
  heartIcon: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  productLinkButton: {
    backgroundColor: '#3f7ea3',
    padding: 10,
    borderRadius: 5,
    marginTop: 10,
    alignItems: 'center',
  },
  productLinkButtonText: {
    color: '#fff',
    fontSize: 16,
  },
});

export default Box3;
