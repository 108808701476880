import React from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import { Linking } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';

const ProductDetails = ({ route }) => {
  const { product } = route.params;
  const handleExternalLinkPress = () => {
    Linking.openURL(product.externalLink);
  };

  return (
    <ScrollView>
      <View style={styles.container}>
        <Image
          source={{
            uri:
              'https://geschenkly.de/wp-content/uploads/2015/03/karikatur_vom_foto_cdi194_1.jpg',
          }}
          style={styles.productImage}
        />
        <Text style={styles.productTitle}>{product.name}</Text>
        <Text style={styles.productDescription}>
          {product.description ? product.description : 'No description available'}
        </Text>
        <TouchableOpacity
          style={styles.externalLinkButton}
          onPress={handleExternalLinkPress}
        >
          <FontAwesome name="external-link" size={18} color="#fff" />
          <Text style={styles.externalLinkText}>Externer Link</Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#F6F6F6',
  },
  productTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginTop: 10,
    color: '#333',
  },
  productDescription: {
    fontSize: 16,
    marginTop: 10,
    color: '#333',
  },
  productImage: {
    width: '100%',
    height: 200,
    resizeMode: 'cover',
    borderRadius: 5,
  },
  externalLinkButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#3f7ea3',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
    marginTop: 10,
  },
  externalLinkText: {
    color: '#fff',
    fontSize: 18,
    marginLeft: 5,
  },
});

export default ProductDetails;
