import React, { useState, useContext } from 'react';
import { View, Text, StyleSheet, Linking } from 'react-native';
import { Input, Button } from 'react-native-elements';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthContext from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/AuthContext.js';
import EntryPage from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/WishList/LoggedIn/EntryPage';
import { CheckBox } from 'react-native-elements';

const Friends = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { isAuthenticated, handleSuccessfulLogin } = useContext(AuthContext);
  if (isAuthenticated) {
    return <EntryPage />;
  }

  const handleLogin = async () => {
  try {
    const response = await fetch('https://www.schindler-ventures.de:3000/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      const error = await response.json();
      console.log("Server error: ", error.error);

      if (error.error === 'User not found') {
        setError('E-Mail-Adresse nicht gefunden. Bitte überprüfen Sie Ihre E-Mail-Adresse oder registrieren Sie sich.');
        return;
      } else if (error.error === 'Invalid password') {
        setError('Das Passwort ist falsch. Versuchen Sie es erneut.');
        return;
      } else {
        throw new Error(error.error);
      }
    }

    const { token } = await response.json();
    console.log('Token:', token);

    await AsyncStorage.setItem('token', token);
    handleSuccessfulLogin(token);

    navigation.navigate('EntryPage');
  } catch (error) {
    console.log('Error', error.message);
    setError('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
  }
};


  return (
    <View style={styles.container}>

      <Text style={styles.text}>
        Melde dich an, verwalte Listen, bekomme handverlesene Geschenkideen, lass dich vom Geschenkly-Bot inspirieren - alles kostenlos bei Geschenkly!
      </Text>
      {error ? <Text style={styles.error}>{error}</Text> : null}

      <Input
        label="E-Mail"
        value={email}
        onChangeText={setEmail}
        autoCapitalize="none"
        keyboardType="email-address"
        inputStyle={styles.input}
        labelStyle={styles.label}
      />
      <Input
        label="Passwort"
        value={password}
        onChangeText={setPassword}
        secureTextEntry
        inputStyle={styles.input}
        labelStyle={styles.label}
      />
      <Button
        title="Anmelden"
        onPress={handleLogin}
        buttonStyle={styles.button}
      />
      <Button
        title="Passwort vergessen?"
        onPress={() => navigation.navigate('ResetPassword')}
        type="clear"
        titleStyle={styles.forgotPassword}
      />
      <View style={styles.space} />
      <Button
        title="Registrieren"
        onPress={() => navigation.navigate('RegisterScreen')}
        buttonStyle={styles.registerButton}
      />
      <Text
        style={styles.impressumLink}
        onPress={() => Linking.openURL('https://geschenkly.de/impressum/')}
      >
        Impressum
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
    backgroundColor: '#3C3F41',
  },
  text: {
    fontSize: 18,
    color: '#fff',
    marginBottom: 30,
    textAlign: 'center',
  },
  input: {
    color: '#fff',
  },
  label: {
    color: '#fff',
    fontWeight: 'bold',
  },
  button: {
    backgroundColor: '#EC823A',
    marginBottom: 10,
  },
  space: {
    height: 20, // oder wie viel Platz Sie möchten
  },
  impressumLink: {
    color: '#fff',
    textDecorationLine: 'underline',
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  link: {
    color: '#fff',
    textDecorationLine: 'underline',
  },
  checkboxContainer: {
    backgroundColor: '#3C3F41',
    borderColor: '#3C3F41',
  },
  checkboxText: {
    color: '#fff',
  },
  error: {
    color: '#ff0000',
    textAlign: 'center',
    marginBottom: 10,
    fontSize: 20,
    fontWeight: 'bold',
  },
  registerButton: {
    backgroundColor: '#f8a978',
    marginBottom: 10,
  },
  forgotPassword: {
  color: '#f8a978', // Farbe des "Passwort vergessen?" Buttons
},
});

export default Friends;
