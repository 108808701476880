import React, { useContext, useEffect, useState } from 'react';
import { Modal, Text, TouchableOpacity, View, StyleSheet, TextInput } from 'react-native';
import jwtDecode from 'jwt-decode';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import AuthContext from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/screens/AuthContext.js';


const deleteUserAccount = async (userId) => {
  try {
    const response = await fetch(
      `https://www.schindler-ventures.de:3000/users/${userId}`,
      {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/jason',
        },
      }
    );

    if (response.ok) {
      console.log('Account gelöscht:', userId);
      await logout();
    } else {
      console.error('Fehler beim Löschen des Accounts:', response.status);
    }
  } catch (error) {
    console.error('Fehler beim Löschen des Accounts:', error);
  }
};



const Account = () => {
  const [userId, setUserId] = useState(null); // Zustand für userId hinzufügen
  const [modalVisible, setModalVisible] = useState(false); // Zustand für Modal-Sichtbarkeit hinzufügen
  const navigation = useNavigation(); // Hier verwenden wir den useNavigation Hook
  const { isAuthenticated, handleSuccessfulLogin, handleLogout } = useContext(AuthContext);
  const [changePasswordModalVisible, setChangePasswordModalVisible] = useState(false);  // State for change password modal
  const [newPassword, setNewPassword] = useState('');
  const [passwordChangedSuccessfully, setPasswordChangedSuccessfully] = useState(false);

  const logout = async () => {
    await AsyncStorage.removeItem('token'); // Hier entfernen wir den Token aus dem AsyncStorage
    handleLogout(false);
    navigation.navigate('Root');
  };
  const changePassword = async () => {
    try {
      const response = await fetch(
        `https://www.schindler-ventures.de:3000/users/${userId}/password`,
        {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ password: newPassword }),
        }
      );

      if (response.ok) {
        console.log('Password changed');
        setChangePasswordModalVisible(false);
        setNewPassword('');
        setPasswordChangedSuccessfully(true);
      } else {
        console.error('Error changing password:', response.status);
      }
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  useEffect(() => {
    // Holen Sie sich die USER_ID beim Laden der Seite
    (async () => {
      const token = await AsyncStorage.getItem('token');
      let userId = null;
      if (token) {
        const decoded = jwtDecode(token);
        userId = decoded.userId;
      }
      if (userId) {
        setUserId(userId); // Setzen Sie die USER_ID im Zustand
      }
    })();
  }, []);

  const handleDeleteAccount = () => {
    setModalVisible(true);
  };

  return (
    <View style={styles.container}>

      <Text style={styles.text}>Hier können Sie Ihr Konto verwalten.</Text>
      <TouchableOpacity onPress={logout}>
        <Text style={styles.logoutButton}>Logout</Text>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => setChangePasswordModalVisible(true)}>
        <Text style={styles.changePasswordButton}>Passwort ändern</Text>
      </TouchableOpacity>
      {passwordChangedSuccessfully ? <Text style={styles.successMessage}>Passwort wurde erfolgreich geändert.</Text> : null}
      <TouchableOpacity onPress={handleDeleteAccount}>
        <Text style={styles.deleteButton}>Konto löschen</Text>
      </TouchableOpacity>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Sind Sie sicher, dass Sie Ihr Konto löschen möchten?</Text>

            <TouchableOpacity
              style={{ ...styles.openButton, backgroundColor: "#2196F3" }}
              onPress={() => {
                setModalVisible(!modalVisible);
                deleteUserAccount(userId);
              }}
            >
              <Text style={styles.textStyle}>Ja, löschen</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ ...styles.openButton, backgroundColor: "#2196F3" }}
              onPress={() => {
                setModalVisible(!modalVisible);
              }}
            >
              <Text style={styles.textStyle}>Abbrechen</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <Modal
        animationType="slide"
        transparent={true}
        visible={changePasswordModalVisible}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>Geben Sie Ihr neues Passwort ein:</Text>

            <TextInput
              secureTextEntry
              style={styles.input}
              onChangeText={setNewPassword}
              value={newPassword}
            />

            <TouchableOpacity
              style={{ ...styles.openButton, backgroundColor: "#2196F3" }}
              onPress={changePassword}
            >
              <Text style={styles.textStyle}>Passwort ändern</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ ...styles.openButton, backgroundColor: "#2196F3" }}
              onPress={() => {
                setChangePasswordModalVisible(false);
              }}
            >
              <Text style={styles.textStyle}>Abbrechen</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    backgroundColor: '#3C3F41',
  },
  text: {
    fontSize: 28,
    textAlign: 'center',
    marginBottom: 20,
    color: '#fff',
  },
  logoutButton: {  // Neue Stildefinition für Logout-Button
    backgroundColor: '#2196F3',
    color: '#fff',
    fontSize: 25,
    padding: 10,
    borderRadius: 5,
    margin: 10,
  },
  deleteButton: {
    color: '#808080',  // Dezentere Farbe für den "Konto löschen"-Button
    fontSize: 15,  // Kleiner Schriftgröße
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  openButton: {
    backgroundColor: "#F194FF",
    borderRadius: 20,
    padding: 10,
    elevation: 2
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center"
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center"
  },
  changePasswordButton: {
    backgroundColor: '#2196F3',
    color: '#fff',
    fontSize: 25,
    padding: 10,
    borderRadius: 5,
    margin: 10,
  },
  input: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
  successMessage: {
   color: '#00ff00',
   textAlign: 'center',
   marginBottom: 10,
   fontSize: 20,
   fontWeight: 'bold',
 },
});

export default Account;
