import React, {useState, useEffect} from 'react';
import {View, Text, FlatList, StyleSheet, TouchableOpacity, Image} from 'react-native';
import WooCommerceApi from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/WooCommerceApi.js';

const getCategories = async () => {
  try {
    const response = await WooCommerceApi.get('products/categories', { params: { per_page: 100 } });
    return response.data;
  } catch (error) {
    console.error('Error fetching categories:', error);
    return [];
  }
};
export default getCategories;
