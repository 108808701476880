import React, { useState } from 'react';
import { View, Text, TextInput, Button, StyleSheet, TouchableOpacity, ScrollView } from 'react-native';
import { Configuration, OpenAIApi } from 'openai';
import Icon from 'react-native-vector-icons/FontAwesome';
import { useNavigation } from '@react-navigation/native';
import { Dimensions } from 'react-native';

const screenWidth = Dimensions.get('window').width;

const EntryPage = () => {
  const navigation = useNavigation();
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');


  const configuration = new Configuration({
    apiKey: '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',
  });
  const openai = new OpenAIApi(configuration);

  const fetchAnswer = async () => {
    try {
      // Save the question
      await fetch('https://www.schindler-ventures.de:3000/api/save-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: input }),
      });

      // Fetch the answer
      const result = await fetch('https://www.schindler-ventures.de:3000/api/ask-question', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: input }),
      });

      const data = await result.json();
      setResponse(data.answer);
    } catch (err) {
      console.log('Error: ' + err);
    }
  };


  const renderButton = (iconName, buttonText, navigationTarget) => (
      <TouchableOpacity
        style={styles.button}
        onPress={() => navigation.navigate(navigationTarget)}
      >
        <Icon name={iconName} size={50} color="#fff" />
        <Text style={styles.iconText}>{buttonText}</Text>
      </TouchableOpacity>
    );

    return (
    <View style={styles.container}>
      <Text style={styles.text}>
        Hier kannst du Wunschlisten anlegen und unseren Geschenkly Bot nutzen
      </Text>
      <ScrollView
  contentContainerStyle={styles.iconContainer}
  showsVerticalScrollIndicator={false}
  showsHorizontalScrollIndicator={false}
>
  {renderButton('birthday-cake', 'Erinnerungen + Geschenkvorschläge', 'BirthdayReminder')}
  {renderButton('gift', 'Geschenke-Assistent', 'GeschenkeAssistent')}
  {renderButton('list', 'Deine Wunschlisten', 'DeineWunschlisten')}
  {renderButton('user', 'Konto', 'Account')}
</ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
container: {
  flex: 1,
  backgroundColor: '#3C3F41',
  paddingTop: 40,
  paddingHorizontal: 20, // Gibt einen seitlichen Padding
  paddingBottom: 40,
},
text: {
  fontSize: 36,
  color: '#fff',
  marginBottom: 30,
  textAlign: 'center', // Zentriert den Text
},
iconContainer: {
  flexDirection: 'row',
  justifyContent: 'space-between', // Anstatt 'space-around'
  flexWrap: 'wrap', // Ermöglicht das Umspringen zur nächsten Zeile
  marginTop: 20,
},
button: {
  backgroundColor: '#EC823A',
  paddingVertical: 10,
  paddingHorizontal: 20,
  borderRadius: 10,
  alignItems: 'center',
  justifyContent: 'center',
  width: screenWidth / 2 - 30, // Adjust the button width based on the screen width
  marginBottom: 20,
},
iconText: {
  fontSize: 18,
  textAlign: 'center',
  marginTop: 10,
  color: '#fff',
},
});

  export default EntryPage;
