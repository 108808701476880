import axios from 'axios';
import { Buffer } from 'buffer';


const WooCommerceApi = axios.create({
  baseURL: 'https://regiovorteile.de/wp-json/my_namespace/v1/',
  headers: {
    Authorization: `Basic ${Buffer.from(
      'ck_8294c67f24b53c829e99c2e595651f013a96a2c3:cs_1e8aa450a6ba13335e3930feac5643875cff48bf'
    ).toString('base64')}`,
    'Content-Type': 'application/json',
  },
});


export default WooCommerceApi;
