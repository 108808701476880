import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  FlatList,
  StyleSheet,
  Linking,
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import WooCommerceApi from '/Users/stephanschindler/Documents/OwnSoftware/GeschenklyApp/src/WooCommerceApi.js';
import FontAwesome from 'react-native-vector-icons/FontAwesome';

const Box2 = () => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    fetchProducts();
    getSelectedProducts();
  }, []);

  const fetchProducts = async () => {
    console.log("Fetching products...");
    try {
      const response = await WooCommerceApi.get('products', {
        params: {
          category: 95,
          per_page: 100, // Setzen Sie hier die Anzahl der Produkte pro Seite
        },
      });
      setProducts(response.data);
      console.log("API Response:", response.data);
    } catch (error) {
      console.log("API Error:", error);
    }
  };

  const getSelectedProducts = async () => {
    try {
      const storedSelectedProducts = await AsyncStorage.getItem('selectedProducts');
      if (storedSelectedProducts) {
        setSelectedProducts(JSON.parse(storedSelectedProducts));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleHeartPress = async (product) => {
    const updatedSelectedProducts = selectedProducts.some(
      (selectedProduct) => selectedProduct.id === product.id
    )
      ? selectedProducts.filter(
          (selectedProduct) => selectedProduct.id !== product.id
        )
      : [...selectedProducts, product];

    setSelectedProducts(updatedSelectedProducts);
    await AsyncStorage.setItem(
      'selectedProducts',
      JSON.stringify(updatedSelectedProducts)
    );
  };

  const renderItem = ({ item }) => {
    const isHeartSelected = selectedProducts.some(
      (selectedProduct) => selectedProduct.id === item.id
    );
    const firstProductImage = item.images && item.images[0] ? item.images[0].src : null;

    return (
      <View style={styles.productItem}>
        <Image
          source={{ uri: 'firstProductImage' }} // Replace with item.images[0].src for actual product image
          style={styles.productImage}
        />
        <Text style={styles.productTitle}>{item.name}</Text>
        <Text style={styles.productPrice}>{item.price}</Text>
        <TouchableOpacity
          style={styles.heartIcon}
          onPress={() => handleHeartPress(item)}
        >
          <FontAwesome
            name={isHeartSelected ? 'heart' : 'heart-o'}
            size={24}
            color={isHeartSelected ? 'red' : 'black'}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.externalLinkButton}
          onPress={() => Linking.openURL(item.external_url)}
        >
          <Text style={styles.externalLinkButtonText}>Öffne externen Link</Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <FlatList
        data={products}
        renderItem={renderItem}
        keyExtractor={(item, index) => (item && item.id ? item.id.toString() : `default-key-${index}`)}
        numColumns={2}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    padding: 10,
  },
  productItem: {
    flex: 1,
    margin: 5,
    backgroundColor: '#f8f8f8',
    padding: 10,
  },
  productTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 5,
  },
  productImage: {
    width: '100%',
    height: 200,
    resizeMode: 'contain',
  },
  heartIcon: {
    position: 'absolute',
    top: 5,
    right: 10,
  },
  message: {
    fontSize: 14,
    color: 'red',
  },
  externalLinkButton: {
    backgroundColor: '#3f7ea3',
    padding: 5,
    borderRadius: 5,
    marginTop: 10,
  },
  externalLinkButtonText: {
    color: '#fff',
    fontSize: 14,
  },
});

export default Box2;
